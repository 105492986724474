import { Component, Fragment } from "react";
import classes from "./Inscription.module.css";
import useHttp from "../useHttp/useHttp";
import Swal from "sweetalert2";
import store from "../Store/Store";
import { useLocation, useNavigate } from "react-router-dom";
import Intro from "../Intro/Intro";
import Missons from "../Missions/Missons";
import Bar from "../Bar/Bar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EntrepriseCarousel from "../EntrepriseCarousel/EntrepriseCarousel";
import Footer from "../Footer/Footer";

class Inscription extends Component {
  constructor(props) {
    super(props);

    this.state = {
      yearsDobtentionDisponible: Array.from(
        { length: 5 },
        (_, index) => new Date().getFullYear() - index
      ),

      user: {
        cin: "",
        nom: "",
        prenom: "",
        email: "",
        dateDeNaissance: "",
        telephone: "",
        diplome: "",
        type: "",
        anneeDobtention: "",
        institue: "",
        cv: "",
      },

      formErrors: {
        cin: {
          isValid: null,
          message: "",
        },
        nom: {
          isValid: null,
          message: "",
        },
        prenom: {
          isValid: null,
          message: "",
        },
        email: {
          isValid: null,
          message: "",
        },
        dateDeNaissance: {
          isValid: null,
          message: "",
        },
        type: {
          isValid: null,
          message: "",
        },
        telephone: {
          isValid: null,
          message: "",
        },
        diplome: {
          isValid: null,
          message: "",
        },
        anneeDobtention: {
          isValid: null,
          message: "",
        },
        institue: {
          isValid: null,
          message: "",
        },
      },

      autreEtablissement: false,
      autreDiplome: false,
    };

    // const registrationForm = this.refs

    console.log(window.innerHeight);
  }

  static contextType = store;

  componentDidMount() {
    console.log(this.state.yearsDobtentionDisponible);
    document.getElementById("institue").addEventListener("change", (e) => {
      e.target.value === "autre" &&
        this.setState({
          ...this.state,
          user: {
            ...this.state.user,
            institue: "",
          },
          autreEtablissement: true,
        });
    });

    document.getElementById("type").addEventListener("change", (e) => {
      e.target.value === "stagiaire" &&
        this.setState({
          ...this.state,
          user: {
            ...this.state.user,
            type: "stagiaire",
          },
          formErrors: {
            ...this.state.formErrors,
            anneeDobtention: {
              message: "ce n'est pas nécessaire tant que vous êtes stagiaire",
              isValid: true,
            },
            diplome: {
              message: "ce n'est pas nécessaire tant que vous êtes stagiaire",
              isValid: true,
            },
          },
        });
    });

    const diplomeElement = document.getElementById("diplome");

    if (diplomeElement) {
      diplomeElement.addEventListener("change", (e) => {
        e.target.value === "autre" &&
          this.setState({
            ...this.state,
            autreDiplome: true,
          });
      });
    }
  }

  componentDidUpdate() {
    const diplomeElement = document.getElementById("diplome");

    if (diplomeElement) {
      diplomeElement.addEventListener("change", (e) => {
        e.target.value === "autre" &&
          this.setState({
            ...this.state,
            autreDiplome: true,
            user: {
              ...this.state.user,
              diplome: "",
            },
            // formErrors: {
            //   ...this.state.formErrors,
            //   message: "",
            //   isValid: true,
            // },
          });
      });
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState(
      (prevState) => ({
        user: {
          ...prevState.user,
          [name]: e.target.type === "file" ? e.target.files[0] : value,
        },
      }),
      () => {
        // console.log(this.state.user.cv.name);
        e.target.type === "file" && this.validateCVField(value, name);
      }
    );
  };

  showNotification = (errorMessage) => {
    toast(errorMessage, {
      closeButton: true,
      progress: false,
      positionClass: "toast-top-right",
      theme: "dark",
      autoClose: 1000,
    });
  };

  // setValidationField(isValid, message, fieldName) {
  //   this.setState({
  //     ...this.state,
  //     formErrors: {
  //       ...this.state.formErrors,
  //       [fieldName]: {
  //         isValid: isValid,
  //         message: "message",
  //       },
  //     },
  //   });

  //   console.log(this.state.formErrors[fieldName])

  //   // pour reiniaitaliser le champ
  //   !isValid && fieldName !== "cv" && this.setState({
  //     ...this.state,
  //     user: {
  //       ...this.state.user,
  //       [fieldName]: "",
  //     }
  //   })
  // }

  setValidationField(isValid, message, fieldName) {
    this.setState(
      (prevState) => ({
        formErrors: {
          ...prevState.formErrors,
          [fieldName]: {
            isValid: isValid,
            message: message,
          },
        },
        user: {
          ...prevState.user,
          [fieldName]:
            !isValid && fieldName !== "cv" ? "" : prevState.user[fieldName],
        },
      }),
      () => {
        // console.log(this.state.formErrors[fieldName]);
      }
    );
  }

  validateField(fieldName, value) {
    const { formErrors } = this.state;

    switch (fieldName) {
      case "cin":
        if (value.trim() === "") {
          this.showNotification("champ est obligatoire");
          this.setValidationField(false, "champ est obligatoire", fieldName);
          break;
        }
        this.setValidationField(true, "", fieldName);
        break;
      case "nom":
        if (value.trim() === "") {
          this.showNotification("champ est obligatoire");
          this.setValidationField(false, "champ est obligatoire", fieldName);
          break;
        }
        if (!/^[A-Za-z\s]+$/.test(value)) {
          this.showNotification("votre nom doit être des lettres");
          this.setValidationField(
            false,
            "votre nom doit être des lettres",
            fieldName
          );
          break;
        }
        this.setValidationField(true, "", fieldName);
        break;
      case "prenom":
        if (value.trim() === "") {
          this.showNotification("champ est obligatoire");
          this.setValidationField(false, "champ est obligatoire", fieldName);
          break;
        }
        if (!/^[A-Za-z\s]+$/.test(value)) {
          this.showNotification("votre prénom doit être des lettres");
          this.setValidationField(
            false,
            "votre prénom doit être des lettres",
            fieldName
          );
          break;
        }
        this.setValidationField(true, "", fieldName);
        break;
      case "email":
        if (value.trim() === "") {
          this.showNotification("champ est obligatoire");
          this.setValidationField(false, "champ est obligatoire", fieldName);
          break;
        }

        if (!value.includes("@") || !value.includes(".com")) {
          this.showNotification("Tapez votre email correctement");
          this.setValidationField(
            false,
            "Tapez votre email correctement",
            fieldName
          );
          break;
        }
        this.setValidationField(true, "", fieldName);
        break;
      case "dateDeNaissance":
        const year = new Date().getFullYear();
        const inputDate = new Date(value).getFullYear();

        if (value.trim() === "") {
          this.showNotification("champ est obligatoire");
          this.setValidationField(false, "champ est obligatoire", fieldName);
          break;
        }
        if (inputDate > year) {
          this.showNotification(
            "L'année doit être inférieure à l'année actuelle."
          );
          this.setValidationField(
            false,
            "L'année doit être inférieure à l'année actuelle.",
            fieldName
          );
          break;
        }
        if (year - inputDate > 30) {
          this.showNotification("L'âge doit être inférieur à 30 ans.");
          this.setValidationField(
            false,
            "L'âge doit être inférieur à 30 ans.",
            fieldName
          );
          break;
        }
        if (year - inputDate <= 17) {
          this.showNotification(
            "L'âge doit être supérieur à ou égal 17 ans pour vous inscrire."
          );
          this.setValidationField(
            false,
            "L'âge doit être supérieur à ou égal 17 ans pour vous inscrire.",
            fieldName
          );
          break;
        }
        this.setValidationField(true, "", fieldName);
        break;
      case "telephone":
        if (value.trim() === "") {
          this.showNotification("champ est obligatoire");
          this.setValidationField(false, "champ est obligatoire", fieldName);
          break;
        }
        if (!/^\d+$/.test(value)) {
          this.showNotification("le numéro de téléphone est incorrect");
          this.setValidationField(
            false,
            "le numéro de téléphone est incorrect",
            fieldName
          );
          break;
        }
        this.setValidationField(true, "", fieldName);
        break;
      case "type":
        if (value.trim() === "") {
          this.showNotification("champ est obligatoire");
          this.setValidationField(false, "champ est obligatiore", fieldName);
          break;
        }
        this.setValidationField(true, "", fieldName);
        break;
      case "diplome":
        if (value.trim() === "") {
          this.showNotification("champ est obligatoire");
          this.setValidationField(false, "champ est obligatoire", fieldName);
          break;
        }
        this.setValidationField(true, "", fieldName);
        break;
      case "anneeDobtention":
        if (value.trim() === "") {
          this.showNotification(`champ est obligatoire`);
          this.setValidationField(false, "champ est obligatoire", fieldName);
          break;
        }

        if (!/^\d+$/.test(value)) {
          this.showNotification(`vous devez tapez des nombres`);
          this.setValidationField(
            false,
            "vous devez tapez des nombres",
            fieldName
          );
          break;
        }

        if (value > new Date().getFullYear()) {
          this.showNotification(
            "L'année sélectionnée doit être inférieure à l'année actuelle"
          );
          this.setValidationField(
            false,
            "L'année sélectionnée doit être inférieure à l'année actuelle",
            fieldName
          );
          break;
        }

        if (value < new Date().getFullYear() - 5) {
          this.showNotification(
            `L'année sélectionnée doit être supérieure à ${
              new Date().getFullYear() - 5
            }`
          );
          this.setValidationField(
            false,
            `L'année sélectionnée doit être supérieure à ${
              new Date().getFullYear() - 5
            }`,
            fieldName
          );
          break;
        }
        this.setValidationField(true, "", fieldName);
        break;
      case "institue":
        if (value.trim() === "") {
          this.showNotification("champ est obligatoire");
          this.setValidationField(false, "champ est obligatoire", fieldName);
        }
        this.setValidationField(true, "", fieldName);
        break;
      default:
        break;
    }
  }

  validateCVField = (value, fieldName) => {
    if (value === "") {
      this.showNotification("Le champ CV est obligatoire");
      this.setValidationField(false, "Le champ CV est obligatoire", fieldName);
      return false;
    }

    // const allowedExtensions = /(\.pdf)$/i;
    const allowedExtensions = /(\.pdf|\.docx?|\.doc)$/i;

    const isValidExtension = allowedExtensions.test(value);

    if (!isValidExtension) {
      this.showNotification("Le CV doit être au format PDF");
      this.setValidationField(
        false,
        "Le CV doit être au format PDF",
        fieldName
      );
      return false;
    }

    this.setValidationField(true, "", fieldName);
    return true;
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    console.log(this.state.formErrors);
    const isFormValid = Object.values(this.state.formErrors).every((field) => {
      console.log(field);
      return field.isValid === true;
    });

    if (!isFormValid) {
      Swal.fire({
        text: "Veuillez remplir correctement tous les champs obligatoires.",
        icon: "warning",
      });
      return;
    }

    const formData = new FormData();

    formData.append("cin", this.state.user.cin);
    formData.append("nom", this.state.user.nom);
    formData.append("prenom", this.state.user.prenom);
    formData.append("email", this.state.user.email);
    formData.append("dateDeNaissance", this.state.user.dateDeNaissance);
    formData.append("telephone", this.state.user.telephone);
    formData.append("type", this.state.user.type);
    formData.append("diplome", this.state.user.diplome);
    formData.append("anneeDobtention", this.state.user.anneeDobtention);
    formData.append("institue", this.state.user.institue);
    formData.append("cv", this.state.user.cv);

    // formData.append("cin", "bk714507");
    // formData.append("nom", "mezrioui");
    // formData.append("prenom", "hakim");
    // formData.append("email", "mezrioui.hakim@gmail.com");
    // formData.append("dateDeNaissance", "2000-08-09");
    // formData.append("telephone", "0610355438");
    // formData.append("diplome", "esa");
    // formData.append("anneeDobtention", "2020");
    // formData.append("institue", "maarif");
    // formData.append("cv", "maarif");

    console.log(formData);

    const request = {
      url: "https://api.istahh1.com/api/storeInscription",
      method: "POST",
      body: formData,

      //   body: {
      //     cin: this.state.user.cin,
      //     nom: this.state.user.nom,
      //     prenom: this.state.user.prenom,
      //     email: this.state.user.email,
      //     dateDeNaissance: this.state.user.dateDeNaissance,
      //     telephone: this.state.user.telephone,
      //     diplome: this.state.user.diplome,
      //     anneeDobtention: this.state.user.anneeDobtention,
      //     institue: this.state.user.institue,
      //     cv: this.state.user.cv,
      //     // cv: "cv",
      //   },
      //   headers: {
      //     "Content-Type": "application/json",
      //     "Content-Type": "multipart/form-data",
      // "Content-Type": "multipart/form-data;boundary=----WebKitFormBoundaryyrV7KO0BoCBuDbTL"
      //   },
    };

    console.log(request);

    // document.getElementById("registration-form").reset();
    const data = await useHttp.sendRequest(request);

    if (data.errors) {
      Swal.fire({
        title: "error",
        icon: "error",
        text:
          (data.errors.cin && data.errors.cin) ||
          (data.errors.email && data.errors.email) ||
          (data.errors.diplome && data.errors.diplome) ||
          (data.errors.anneeDobtention && data.errors.anneeDobtention) ||
          (data.errors.cv && data.errors.cv),
      });
      return;
    }

    if (data) {
      this.setState({
        user: {
          cin: "",
          nom: "",
          prenom: "",
          email: "",
          dateDeNaissance: "",
          telephone: "",
          type: "",
          diplome: "",
          anneeDobtention: "",
          institue: "",
        },
      });

      Swal.fire({
        title: "Good",
        text: "Your registre is succefully",
        icon: "success",
      }).then(() => {
        this.props.navigate(`/detail/${data.data.id}`);
      });
    }

    console.log(data);
  };

  handleBlur = (e) => {
    const { value, name } = e.target;
    if (value.trim() === "") {
      e.target.classList.add(classes.invalid);
    }

    this.validateField(name, value);
  };

  render() {
    return (
      <Fragment>
        <Intro />

        {/* <Bar /> */}
        <br />

        <Missons />

        <EntrepriseCarousel />

        <Bar />
        <div className={classes.backImage}></div>
        <div className={classes.container}>
          {/* <div > */}
          <div className={` ${classes.description}`}>
            <img
              // src="./images/OFPPT_LogoGradient.png"
              src="./images/logoOfppt.png"
              width={100}
              className={`mt-3  p-0`}
            />
            {/* <h2 className={`${classes.h2}`}>Welcome</h2>
            <h3 className={`${classes.h3}`}>To Our Office</h3> */}
            <h2 className={`${classes.h2}`}>Bienvenue </h2>
            <h3 className={`${classes.h3}`}>dans notre établissement</h3>
            <p className={`${classes.para}`}>
              Le Forum de l'Emploi et du Meeting Professionnel, un événement
              majeur dédié aux stagiaires et lauréats de l'OFPPT, concrétise
              notre engagement envers l'inclusion économique. L'ISTA HAY HASSANI
              I, conscient de son importance, offre une occasion sans pareille
              dans un environnement propice aux rencontres et aux opportunités
              de carrière pour les recruteurs et les jeunes compétences,
              assurant ainsi une meilleure insertion socio-professionnelle.
              Soyez les bienvenus, chers visiteurs, au rendez-vous du 30
              novembre 2023, dans les locaux de l'ISTA HAY
              HASSANI I à Casablanca.
            </p>
          </div>

          <form
            onSubmit={this.handleSubmit}
            id="registration-form"
            className={` ${classes["registration-form"]}`}
            autoComplete="off"
            encType="multipart/form-data"
            // method="POST"
          >
            <div className={classes.backForm}></div>
            <h1 className={`position-relative text-white ${classes.h1}`}>
              Register
            </h1>
            <div className={`${classes["form-group"]}`}>
              <input
                className={this.state.user.cin ? classes.notEmpty : null}
                type="text"
                name="cin"
                id="cin"
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                value={this.state.user.cin}
                maxLength={10}
                // required
              />
              <label htmlFor="cin">Cin</label>
            </div>

            <div className={`${classes["form-group"]}`}>
              <input
                className={this.state.user.nom && classes.notEmpty}
                type="text"
                name="nom"
                id="nom"
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                maxLength={25}
                value={this.state.user.nom}
                // required
              />
              <label htmlFor="nom">Nom</label>
            </div>

            <div className={`${classes["form-group"]}`}>
              <input
                className={this.state.user.prenom ? classes.notEmpty : null}
                type="text"
                name="prenom"
                id="prenom"
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                maxLength={25}
                value={this.state.user.prenom}
              />
              <label htmlFor="email">Prénom</label>
            </div>

            <div className={`${classes["form-group"]}`}>
              <input
                className={this.state.user.email ? classes.notEmpty : null}
                type="email"
                name="email"
                id="email"
                placeholder="  "
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                maxLength={50}
                value={this.state.user.email}
              />
              <label htmlFor="email">Email</label>
            </div>

            <div className={`${classes["form-group"]}`}>
              <input
                className={
                  this.state.user.dateDeNaissance ? classes.notEmpty : null
                }
                type="date"
                name="dateDeNaissance"
                id="dateDeNaissance"
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                value={this.state.user.dateDeNaissance}
                // required
              />
              <label htmlFor="dateDeNaissance">Date De Naissance</label>
            </div>

            <div className={`${classes["form-group"]}`}>
              <input
                className={this.state.user.telephone ? classes.notEmpty : null}
                type="tel"
                name="telephone"
                id="telephone"
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                value={this.state.user.telephone}
              />
              <label htmlFor="telephone">Téléphone</label>
            </div>

            <div className={`${classes["form-group"]}`}>
              <select
                id="type"
                name="type"
                className={
                  this.state.user.type !== "" ? classes.notEmpty : null
                }
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                value={this.state.user.type}
                required
              >
                <option value={""}>Lauréats ou Stagiaire</option>
                <option value={"lauréat"}>Lauréat</option>
                <option value={"stagiaire"}>Stagiaire</option>
              </select>
              <label htmlFor="type">Vous êtes</label>
            </div>

            {this.state.user.type === "lauréat" && (
              <div className={`${classes["form-group"]}`}>
                {/* <input
                      className={
                        this.state.user.anneeDobtention ? classes.notEmpty : null
                      }
                      type="number"
                      name="anneeDobtention"
                      id="anneeDobtention"
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                      maxLength={4}
                      min={new Date().getFullYear() - 5}
                      max={new Date().getFullYear()}
                      value={this.state.user.anneeDobtention}
                    /> */}
                <select
                  id="anneeDobtention"
                  name="anneeDobtention"
                  className={
                    this.state.user.anneeDobtention !== ""
                      ? classes.notEmpty
                      : null
                  }
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                >
                  <option value={""}>Sélectionner L'année d'obtention </option>
                  {this.state.yearsDobtentionDisponible.map((year, i) => (
                    <option key={i} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
                <label htmlFor="anneeDobtention">L'année D'obtention</label>
              </div>
            )}

            {this.state.user.type === "lauréat" && (
              <div className={`${classes["form-group"]}`}>
                {!this.state.autreDiplome && (
                  <select
                    id="diplome"
                    name="diplome"
                    className={
                      this.state.user.diplome !== "" ? classes.notEmpty : null
                    }
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    value={this.state.user.diplome}
                    required
                  >
                    <option value={""}>Sélection votre diplôme</option>
                    <option value={"Electromecanique Des Systeme Automatisé"}>
                      Electromecanique Des Systeme Automatisé
                    </option>
                    <option value={"Informatique Digital"}>
                      Informatique Digital
                    </option>
                    <option value={"Technicien Spécialisé en Commerce"}>
                      Technicien Spécialisé en Commerce
                    </option>
                    <option
                      value={"Technicien Spécialisé en Gestion des Entreprises"}
                    >
                      Technicien Spécialisé en Gestion des Entreprises
                    </option>
                    <option value={"Electricité d'Entretien Industriel"}>
                      Electricité d'Entretien Industriel
                    </option>
                    <option
                      value={
                        "Technicien en Maintenance et Support Informatique et Réseaux"
                      }
                    >
                      Technicien en Maintenance et Support Informatique et
                      Réseaux
                    </option>
                    <option value={"autre"}>autre ...</option>
                  </select>
                )}

                {this.state.autreDiplome && (
                  <input
                    className={
                      this.state.user.diplome ? classes.notEmpty : null
                    }
                    type="text"
                    name="diplome"
                    id="diplome"
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    value={this.state.user.diplome}
                    autoFocus
                  />
                )}

                <label htmlFor="diplome">Diplôme</label>
              </div>
            )}

            <div className={`${classes["form-group"]}`}>
              {!this.state.autreEtablissement && (
                <select
                  id="institue"
                  name="institue"
                  className={
                    this.state.user.institue !== "" ? classes.notEmpty : null
                  }
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                >
                  <option value={""}>Choisir un institut</option>

                  <option
                    value={
                      "INSTITUT SPECIALISE DE TECHNOLOGIE APPLIQUEE HAY HASSANI 1 CASABLANCA"
                    }
                  >
                    INSTITUT SPECIALISE DE TECHNOLOGIE APPLIQUEE HAY HASSANI 1
                    CASABLANCA
                  </option>
                  <option
                    value={
                      "INSTITUT SPECIALISE DE TECHNOLOGIE APPLIQUEE NTIC SIDI MAAROUF CASABLANCA"
                    }
                  >
                    INSTITUT SPECIALISE DE TECHNOLOGIE APPLIQUEE NTIC SIDI
                    MAAROUF CASABLANCA
                  </option>
                  <option
                    value={
                      "INSTITUT SPECIALISE DE TECHNOLOGIE APPLIQUEE AIN CHOK-INARA CASABLANCA"
                    }
                  >
                    INSTITUT SPECIALISE DE TECHNOLOGIE APPLIQUEE AIN CHOK-INARA
                    CASABLANCA
                  </option>
                  <option
                    value={
                      "INSTITUT SPECIALISE DE TECHNOLOGIE APPLIQUEE LAHRAOUINE"
                    }
                  >
                    INSTITUT SPECIALISE DE TECHNOLOGIE APPLIQUEE LAHRAOUINE
                  </option>
                  <option
                    value={
                      "CENTRE SOCIO-EDUCATIF ET D'INSERTION DES JEUNES MADINAT ARRAHMA II NOUACEUR"
                    }
                  >
                    CENTRE SOCIO-EDUCATIF ET D'INSERTION DES JEUNES MADINAT
                    ARRAHMA II NOUACEUR
                  </option>
                  <option
                    value={
                      "Institut Spécialisé de Technologie Appliquée de Confection Sidi Maârouf Casablanca"
                    }
                  >
                    Institut Spécialisé de Technologie Appliquée de Confection
                    Sidi Maârouf Casablanca
                  </option>
                  <option
                    value={"ESPACE SOCIO-EDUCATIF MADINAT ERRAHMA NOUACEUR"}
                  >
                    ESPACE SOCIO-EDUCATIF MADINAT ERRAHMA NOUACEUR
                  </option>
                  <option
                    value={
                      "CITE DES METIERS ET DES COMPETENCES CASABLANCA - SETTAT"
                    }
                  >
                    CITE DES METIERS ET DES COMPETENCES CASABLANCA - SETTAT
                  </option>
                  <option
                    value={
                      "INSTITUT SPECIALISE DE FORMATION DE L'OFFSHORING CASABLANCA"
                    }
                  >
                    INSTITUT SPECIALISE DE FORMATION DE L'OFFSHORING CASABLANCA
                  </option>
                  <option
                    value={
                      "CENTRE DE FORMATION DANS LES METIERS DU TERTIARE OISIS CASABLANCA"
                    }
                  >
                    CENTRE DE FORMATION DANS LES METIERS DU TERTIARE OISIS
                    CASABLANCA
                  </option>
                  <option
                    value={
                      "institut supérieure de gestion et de l'informatique de casablanca"
                    }
                  >
                    institut supérieure de gestion et de l'informatique de
                    casablanca
                  </option>
                  <option
                    value={
                      "INSTITUT SPECIALISE DE TECHNOLOGIE APPLIQUEE BEN M'SIK CASABLANCA"
                    }
                  >
                    INSTITUT SPECIALISE DE TECHNOLOGIE APPLIQUEE BEN M'SIK
                    CASABLANCA
                  </option>
                  <option
                    value={
                      "Institut Spécialisé de Technologie Appliquée Lissasfa Casablanca"
                    }
                  >
                    Institut Spécialisé de Technologie Appliquée Lissasfa
                    Casablanca
                  </option>
                  <option
                    value={
                      "Institut Spécialisé de Technologie Appliquée Hay Hassani 2 Casablanca"
                    }
                  >
                    Institut Spécialisé de Technologie Appliquée Hay Hassani 2
                    Casablanca
                  </option>
                  <option
                    value={
                      "Institut Spécialisé de Technologie Appliquée Génie Mécanique Casablanca"
                    }
                  >
                    Institut Spécialisé de Technologie Appliquée Génie Mécanique
                    Casablanca
                  </option>
                  <option
                    value={
                      "Institut Spécialisé de Technologie Appliquée Roches Noires Casablanca"
                    }
                  >
                    Institut Spécialisé de Technologie Appliquée Roches Noires
                    Casablanca
                  </option>
                  <option
                    value={
                      "Institut Spécialisé du Bâtiment et des Travaux Publics Hay Errahma Casablanca"
                    }
                  >
                    Institut Spécialisé du Bâtiment et des Travaux Publics Hay
                    Errahma Casablanca
                  </option>

                  <option value={"autre"}>autre ...</option>
                </select>
              )}
              {this.state.autreEtablissement && (
                <input
                  className={`${this.state.user.cv ? classes.notEmpty : null}`}
                  type="text"
                  name="institue"
                  id="institue"
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  autoFocus
                />
              )}
              <label htmlFor="institue">Institut</label>
            </div>

            <div className={`${classes["form-grou"]} ${classes.attacherCv}`}>
              <input
                className={`${classes.cvField} ${
                  this.state.user.cv ? classes.notEmpty : null
                }`}
                type="file"
                name="cv"
                id="cv"
                onChange={this.handleChange}
                onBlur={this.handleBlur}
              />
              <label htmlFor="cv">Attaché votre cv</label>
              {this.state.user.cv && <span>{this.state.user.cv.name}</span>}
              {/* <span className={`text-white`}>Choisir</span> */}
            </div>

            <div className={`${classes["form-group"]}`}>
              <button className={`${classes.btn}`} type="submit">
                Register
              </button>
            </div>
          </form>
          {/* </div> */}
        </div>

        <div className={classes.separation}></div>
        <Bar />
        <Footer />

        <ToastContainer />

        {/* <button
          onClick={() => {
            this.setState(() => ({
              user: {
                cin: "bk714507",
                nom: "mezrioui",
                prenom: "hakim",
                email: "mezrioui.hakim@gmail.com",
                dateDeNaissance: "2000/08/09",
                telephone: "0610355438",
                diplome: "esa",
                anneeDobtention: "2019",
                institue: "maarif",
                cv: "",
              },
            }));
          }}
        >
          click
        </button> */}
      </Fragment>
    );
  }
}

const InscriptionNav = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return <Inscription navigate={navigate} location={location} />;
};

export default InscriptionNav;
