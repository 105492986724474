import HeaderHackaton from "../header.js/Header";
import styles from "./ProposDuHackathon.module.css";

const ProposDuHackathon = () => {
  return (
    <div className={styles.body}>
      <div className={styles.animation}></div>
      <div className={styles.animation2}></div>

      <img src="/images/idea-min.jpg" className={styles.imageSection} />
      <div className={styles.content}>
        <HeaderHackaton />
        <h1 className={styles.title}>A PROPOS DU HACKATHON</h1>
        <h3 className={styles["sous-title"]}>La Technologie partout</h3>

        <p className={styles.para}>
          A PROPOS DU HACKATHON Bienvenue à INNOVA-SPHERE 2024, notre hackathon
          annuel qui réunira des équipes multidisciplinaires. L'événement mettra
          en lumière le thème inspirant "Innovations Technologiques pour un
          Avenir Connecté au Maroc: IS CASA". Les participants issus de divers
          instituts tels que l'ISTA Hay Hassani 1, ISTA CFPMS, ISTA NTIC CFMOTI,
          Centre Mixte Lalla Aicha, ISTA Roches Noires, ISGA, ISGI Centre
          Philips, ISTA El Hank et La Faculté des sciences Ain Chock Casablanca
          seront encouragés à explorer des domaines tels que l'intelligence
          artificielle, l'Internet des objets, la cybersécurité, et les
          technologies émergentes. Cet événement s'inscrit dans une vision plus
          large d'intégration de la technologie dans tous les domaines,
          combinant l’Agriculture, la Médecine, l’Economie.. Ainsi, les équipes
          auront l'opportunité unique de fusionner des compétences variées et de
          favoriser une collaboration interdisciplinaire exceptionnelle.
          L'objectif principal d'INNOVA-SPHERE 2024 est de stimuler
          l'innovation, de développer la créativité, d’encourager le travail
          d’équipe et de découvrir de nouveaux talents. Avec un jury composé
          d'experts reconnus en formation, de chefs d’entreprise, de
          responsables de ressources humaines et de docteurs universitaires, un
          budget total de 20 000 MAD est alloué pour récompenser les innovations
          les plus prometteuses et promouvoir la technologie dans divers
          domaines.
        </p>
      </div>
    </div>
  );
};

export default ProposDuHackathon;
