import HeaderHackaton from "../header.js/Header";
import styles from "./DevenirUnSponsor.module.css";

const DevenirUnSponsor = () => {
  return (
    <div className={styles.body}>
      <div className={styles.animation}></div>
      <div className={styles.animation2}></div>

      <img src="/images/business.jpg" className={styles.imageSection} />
      <div className={styles.content}>
        <HeaderHackaton />
        <h1 className={styles.title}>DEVENIR UN SPONSOR</h1>
        <h3 className={styles["sous-title"]}>
          Pourquoi Soutenir INNOVA-SPHERE 2024 ?
        </h3>

        <p className={styles.para}>
          <div>
            Promouvoir l'Innovation : En soutenant ce hackathon, vous participez
            à la promotion de l'innovation technologique au sein de la jeunesse,
            favorisant ainsi un écosystème numérique dynamique.
          </div>
        
          <div className={styles.section}>
            <span className={styles.titleSction}>Engagement envers l'Éducation</span>
            INNOVA-SPHERE 2024 offre une
            opportunité aux étudiants de développer leurs compétences, de
            résoudre des problèmes concrets et d'apprendre par la pratique, vous
            alignant ainsi avec des valeurs éducatives.
          </div>
        
          <div className={styles.section}>
            <span className={styles.titleSction}>Visibilité</span>
            En tant que sponsor, vous bénéficierez d'une
            visibilité significative sur tous les supports de communication
            associés à l'événement, renforçant ainsi votre image de leader
            engagé dans le développement technologique.
          </div>
        
          <div className={styles.section}>
            <span className={styles.titleSction}>Réseautage et Recrutement</span>
            INNOVA -SPHERE 2024 offre une
            plateforme idéale pour établir des liens avec les talents émergents.
            Participez au hackathon pour rencontrer des étudiants passionnés et
            potentiels collaborateurs
          </div>
        </p>
      </div>
    </div>
  );
};

export default DevenirUnSponsor;
