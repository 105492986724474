import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

const ShowCv = () => {
  const params = useParams();
  const [pdfUrl, setPdfUrl] = useState("");

  useEffect(() => {
    fetch(`https://api.istahh1.com/api/showCv/${params.id}`, {
      method: "get",
    })
      .then((response) => {
        if (!response.ok) {
          throw Error("Network response was not ok");
        }

        return response.blob();
      })
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        setPdfUrl(url);
      });
  }, [params.id]);

  return (
    <div>
      <div style={{ height: "100vh", overflow: "hidden" }}>
        {pdfUrl && (
          <embed
            src={pdfUrl}
            type="application/pdf"
            width="100%"
            height="100%"
          />
        )}
      </div>
    </div>
  );
};

export default ShowCv;
