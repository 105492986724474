import React from "react";
import styles from "./ContactUsForm.module.css"; // Import your module CSS file
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

function ContactUsForm() {
  const navigate = useNavigate();

  const onSubmitHandler = (e) => {
    e.preventDefault();

    Swal.fire({
      text: "Votre message a été rendu avec succès.",
      icon: "success",
    }).then(() => {
      navigate("/");
    });
  }
  return (
    <div className={styles.body}>
      <form className={styles.formContact} onSubmit={onSubmitHandler}>
        <div className={`container d-flex align-items-center flex-column`}>
          <div className={`row`}>
            <h1 className="text-white">Contact Us</h1>
          </div>
          <div className={`${`row`} ${styles["input-container"]}`}>
            <div className="col-xs-12">
              <div className={`${styles["styled-input"]} ${styles.wide}`}>
                <input type="text" required />
                <label>Name</label>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className={styles["styled-input"]}>
                <input type="email" required />
                <label>Email</label>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className={`${styles["styled-input"]} ${styles.floatRight}`}>
                <input type="tel" required />
                <label>Phone Number</label>
              </div>
            </div>
            <div className="col-xs-12">
              <div className={`${styles["styled-input"]} ${styles.wide}`}>
                <textarea required></textarea>
                <label>Message</label>
              </div>
            </div>
            <div className="col-xs-12">
              <button
                style={{border: "none"}}
                className={`${styles["btn-lrg"]} ${styles["submit-btn"]}`}
              >
                Send Message
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ContactUsForm;
