// import Inscription from "./components/Inscription/Inscription";
// import GuardRoute from "./components/GuardRoute/GuardRoute";
// import { LocalDining } from "@material-ui/icons";
// import ListInscription from "./components/ListInscription/ListInscription";
import EditForm from "./components/editForm/EditForm";
import NotFound from "./components/NotFound/NotFound";
import DetailInscription from "./components/DetailInscription/DetailInscription";
// import Loading from "./components/Loading/Loading";
import InscriptionNav from "./components/Inscription/Inscription";
import Login from "./components/Login/Login";
import AdminLogin from "./components/Admin/Login/Login";
import ListInscriptionNav from "./components/ListInscription/ListInscription";
import AdminListInscription from "./components/Admin/AdminListInscription/ListInscription";
import ShowCv from "./components/ShowCv/ShowCv";
// import ListEntreprises from "./components/ListEntreprises/ListEntreprises";
// import EditEntreprise from "./components/EditEntreprise/EditEntreprise";
import AddEntreprise from "./components/AddEntreprise/AddEntreprise";
import ListEntreprisesNav from "./components/ListEntreprises/ListEntreprises";
import AdminListEtreprise from "./components/Admin/AdminListEntreprises/ListEntreprises";
import Entretiens from "./components/Entretiens/Entretiens";
import AdminEntretiens from "./components/Admin/AdminEntretiens/Entretiens";
import InscriptionEntreprises from "./components/InscriptionEntreprises/InscriptionEntreprises";
import AdminInscriptionEntreprises from "./components/Admin/AdminInscriptionEntreprises/InscriptionEntreprises";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from "react";
import ContactUsForm from "./components/ContactUs/ContactUsForm";
import Hackathon from "./components/Hackathon/Hackathon";
import TableDeContenu from "./components/Hackathon/TableDeContenu/TableDeContenu";
import Introduction from "./components/Hackathon/introduction/Introduction";
import ProposDuHackathon from "./components/Hackathon/ProposDuHackathon/ProposDuHackathon";
import Agenda from "./components/Hackathon/Agenda/Agenda";
import DevenirUnSponsor from "./components/Hackathon/DevenirUnSponsor/DevenirUnSponsor";
import Conclusion from "./components/Hackathon/Conclusion/Conclusion";

// const InscriptionNav = React.lazy(() => import("./components/Inscription/Inscription"));
// const ListEntreprisesNav = React.lazy(() => import("./components/ListEntreprises/ListEntreprises"));
// const AddEntreprise = React.lazy(() => import("./components/AddEntreprise/AddEntreprise"));
// const ListInscriptionNav = React.lazy(() => import("./components/ListInscription/ListInscription"));
// const EditForm = React.lazy(() => import("./components/editForm/EditForm"));
// const DetailInscription = React.lazy(() => import("./components/DetailInscription/DetailInscription"));
// const ShowCv = React.lazy(() => import("./components/ShowCv/ShowCv"));
// const Entretiens = React.lazy(() => import("./components/Entretiens/Entretiens"));
// const InscriptionEntreprises = React.lazy(() => import("./components/InscriptionEntreprises/InscriptionEntreprises"));
// const Login = React.lazy(() => import("./components/Login/Login"));
// const NotFound = React.lazy(() => import("./components/NotFound/NotFound"));

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<InscriptionNav />} />
        <Route path="/listEntreprises" element={<ListEntreprisesNav />}>
          {/* <Route path="editEntreprise/:id" element={<EditEntreprise />} /> */}
          <Route path="addEntreprise" element={<AddEntreprise />} />
        </Route>
        <Route path="/listInscription" element={<ListInscriptionNav />}>
          {/* <Route path="editForm/:id" element={<EditForm />} /> */}
        </Route>
        <Route path="/detail/:id" element={<DetailInscription />} />
        {/* <Route path="/loading" element={<Loading><h5>Loading...</h5></Loading>} /> */}
        <Route path="showCv/:id" element={<ShowCv />} />
        <Route path="showEntretiens/:entreprise/:id" element={<Entretiens />} />
        <Route
          path="/inscriptionEntreprises/:id"
          element={<InscriptionEntreprises />}
        />
        <Route path="/login" element={<Login />} />
        <Route path="/contactUs" element={<ContactUsForm />} />

        {/* admin */}
        <Route path="/adminLogin" element={<AdminLogin />} />
        <Route path="/adminListInscription" element={<AdminListInscription />}>
          <Route path="editForm/:id" element={<EditForm />} />
        </Route>
        <Route path="/adminListEntreprises" element={<AdminListEtreprise />}>
          <Route path="addEntreprise" element={<AddEntreprise />} />
        </Route>
        <Route
          path="adminShowEntretiens/:entreprise/:id"
          element={<AdminEntretiens />}
        />
        <Route
          path="/admininscriptionEntreprises/:id"
          element={<AdminInscriptionEntreprises />}
        />
        {/* fin admin */}

        {/* Hackathon */}
        <Route path="/hackathon" element={<Hackathon />} />
        <Route path="/hackathon/plan" element={<TableDeContenu />} />
        <Route path="/hackathon/Introduction" element={<Introduction />} />
        <Route path="/hackathon/ProposDuHackathon" element={<ProposDuHackathon />} />
        <Route path="/hackathon/Agenda" element={<Agenda />} />
        <Route path="/hackathon/DevenirUnSponsor" element={<DevenirUnSponsor />} />
        <Route path="/hackathon/Conclusion" element={<Conclusion />} />
        {/* fin Hackathon */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
