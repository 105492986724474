import { useRef, useState } from "react";
import styles from "./Login.module.css";
import { useContext } from "react";
import store from "../Store/Store";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const codeRef = useRef();
  const ctx = useContext(store);
  const [isCorrect, setIsCorrect] = useState({
    isCorrect: null,
    message: "",
  });
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (codeRef.current.value.trim() === "") {
        setIsCorrect((prevState) => (
            {
                ...prevState,
                message: "You Should Entre The Password",
                isCorrect: false
            }
        ));
        return;
    }
    
    if (codeRef.current.value === ctx.code) {
      ctx.confirmAuth();
      // console.log(ctx.navigateTo);
      navigate(sessionStorage.getItem("navigateTo"));
    }

    setIsCorrect((prevState) => (
        {
            ...prevState,
            message: "The Password Is Incorrect",
            isCorrect: false
        }
    ));
    
    codeRef.current.value = "";
  };

  return (
    <form onSubmit={handleSubmit} className={`${styles.loginForm}`}>
      <div>
        <input
          type="password"
          ref={codeRef}
          className={`form-control ${isCorrect.isCorrect === false && 'is-invalid'}`}
          placeholder="Enter The Password"
        />
        {isCorrect.isCorrect === false && (
          <p className="alert alert-danger mb-1 p-2 mt-1">{ isCorrect.message }</p>
        )}
        <button className="d-block mt-1 btn btn-outline-success">
          Confirm
        </button>
      </div>
    </form>
  );
};

export default Login;
