import HeaderHackaton from "../header.js/Header";
import styles from "./Introduction.module.css";

const Introduction = () => {
  return (
    <div className={styles.body}>
      
      <div className={styles.animation}></div>
      <div className={styles.animation2}></div>

      <img src="/images/intro.jpg" className={styles.imageSection} />

      <div className={styles.content}>
        <HeaderHackaton />
        <h1 className={styles.title}>INTRODUCTION</h1>
        <h3 className={styles["sous-title"]}>A propos des organisateurs</h3>

        <p className={styles.para}>
          INNOVA-SPHERE 2024: Hackathon de l'Innovation Technologique est le
          fruit d'une collaboration entre la Comité d’Autodiscipline qui se
          trouve au niveau de l’institut Spécialisé de Technologie Appliquée
          (Cad ISTA HH1), le Centre de carrière et l'Association Dalil. Ces
          entités ont déjà prouvé leur excellence en organisant avec succès la
          première édition du Concours de Plaidoirie. ISTA HH1, par le biais de
          son Carrière Center, est largement reconnu pour son excellence
          académique et son engagement en faveur de la promotion de l'innovation
          technologique. En outre, il joue un rôle essentiel dans
          l'encouragement des activités de développement personnel via le Cad,
          contribuant ainsi activement à l'épanouissement des étudiants et à
          leur intégration réussie dans le monde professionnel. L'Association
          Dalil, partenaire actif du CAD ISTA HH1, a joué un rôle clé dans la
          gestion réussie de plusieurs évènements. Engagée dans la promotion de
          l'innovation et de la technologie, l'Association Dalil contribue
          ardemment à la création et à la réalisation d'INNOVA- SPHERE 2024. Son
          expérience préalable témoigne de leur dévouement à l'excellence et à
          la promotion d'idées novatrices
        </p>
      </div>
    </div>
  );
};

export default Introduction;
