// import DataTable from 'react-data-table-component';
// import ArrowDownward from '@material-ui/icons/ArrowDownward';
// import "./ListInscription.css";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faDeleteLeft, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

// const ListInscription = () => {
//     const columns = [
//         {
//             name: 'Title',
//             selector: 'title',
//             sortable: true,
//         },
//         {
//             name: 'Year',
//             selector: 'year',
//             sortable: true,
//         },
//         {
//             name: 'Edit',
//             cell: row => (
//                 <button onClick={() => handleEdit(row.id)} className='btn btn-warning'>
//                     <FontAwesomeIcon icon={faEdit} className='text-white'/>
//                 </button>
//             ),
//             button: true, // Allow button elements
//         },
//         {
//             name: 'Delete',
//             cell: row => (
//                 <button onClick={() => handleDelete(row.id)} className='btn btn-danger'>
//                     <FontAwesomeIcon icon={faTrash} />
//                 </button>
//             ),
//             button: true, // Allow button elements
//         },
//     ];

//     const data = [
//         {
//             id: 1,
//             title: 'Beetlejuice',
//             year: '1988',
//         },
//         {
//             id: 2,
//             title: 'Ghostbusters',
//             year: '1984',
//         },
//     ];

//     const handleEdit = (id) => {
//         // Handle edit for the row with the given id
//         console.log('Edit clicked for ID: ', id);
//     };

//     const handleDelete = (id) => {
//         // Handle delete for the row with the given id
//         console.log('Delete clicked for ID: ', id);
//     };

//     return (
//         <div className='container px-5'>
//             <DataTable
//                 pagination
//                 columns={columns}
//                 data={data}
//             />
//         </div>
//     );
// }

// export default ListInscription;

import React, { Component, Fragment, createRef } from "react";
import DataTable from "react-data-table-component";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import "./ListInscription.css";
import Checkbox from "@material-ui/core/Checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faCheckCircle,
  faEdit,
  faFilePdf,
  faInfoCircle,
  faQrcode,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import useHttp from "../useHttp/useHttp";

import store, { StoreConsumer } from "../Store/Store";

import Swal from "sweetalert2";
import { Outlet, Link, useNavigate } from "react-router-dom";
import classes from "./ListInscription.css";
import QrReader from "react-qr-scanner";
import Loading from "../Loading/Loading";
import { useContext } from "react";
import { useEffect } from "react";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import Navigation from "../Navigation/Navigation";

class ListInscription extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      dataFiltred: [],
      filterText: "",
      responseState: 0,
      scan: false,
      result: null,
      entreprises: [],
      options: [],
      entrepriseSelectionne: null,
      idEntrepriseSelectionne: null,
      idPersonneSelectionne: null,
    };

    this.searchRef = createRef();
    this.entrepriseSelectionneRef = createRef();

    this.info = [];
    this.sortIcon = <ArrowDownward />;
    this.url = "https://api.istahh1.com/api/";
  }

  static contextType = store;

  applayData = (dataRec) => {
    this.setState({ ...this.state, data: dataRec.data });
  };

  async componentDidMount() {
    const request = {
      url: "https://api.istahh1.com/api/inscriptions",
    };
    this.setState({ ...this.state, isEdited: this.context.isEdited });
    this.context.fetchData(request);

    try {
      const request = { url: "https://api.istahh1.com/api/entreprises" };
      const entreprises = await useHttp.sendRequest(request);
      console.log(entreprises);
      this.setState({
        ...this.state,
        entreprises: entreprises.data,
        options: entreprises.data.map((entreprise) => {
          return {
            value: entreprise.name,
            label: (
              <div>
                <img
                  src={`https://api.istahh1.com/logos/${entreprise.logo}`}
                  height="30px"
                  width="30px"
                  className="me-2"
                />
                <input type="hidden" name="id" value={entreprise.id} />
                {entreprise.name}
              </div>
            ),
          };
        }),
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    // const data = this.context.data;
    // const data = await useHttp.sendRequest({
    //   url: "https://api.istahh1.com/api/inscriptions",
    // });
    // this.setState({ data: data.data, dataFiltred: data.data, responseState: data.status });
    // this.info = data.data;
  }

  // async componentDidUpdate(prevProps, prevState) {
  // console.log("prevState", prevState);
  // const data = await useHttp.sendRequest({
  //   url: "https://api.istahh1.com/api/inscriptions",
  // });
  // const request = {
  //   url: "https://api.istahh1.com/api/inscriptions",
  // }
  // this.context.fetchData(request);

  // this.setState({ data: this.context.data, dataFiltred: prevState });

  // console.log("info", this.info);
  // }

  // shouldComponentUpdate(nextProps, nextState) {
  //     console.log(nextState);
  //     // console.log(this.state.data);
  //     this.info = nextState.dataFiltred;
  //     if(this.state.filterText !== nextState.filterText) {
  //       return true;
  //     }
  //     if(this.state.data.length === nextState.data.length ) {
  //         return false;
  //     }
  //     // if(next) {

  //     // }
  //   return true;
  // }

  handleEdit = (id) => {
    this.props.history.push("/");
  };

  handleDelete = (id) => {
    // console.log(this.context);

    Swal.fire({
      title: "Warning!",
      text: "Do you want to continue",
      icon: "warning",

      confirmButtonText: "Yes",
      cancelButtonText: "No",
      showCancelButton: true,
    }).then(async (reponse) => {
      if (reponse.isConfirmed) {
        const request = {
          url: `https://api.istahh1.com/api/deleteInsription/${id}`,
          method: "POST",
        };

        this.context.postData(request, true);
        // this.context.fetchData({url: "https://api.istahh1.com/api/inscriptions"});

        // const response = await useHttp.sendRequest(request);
        // if (response.status === 200) {
        //   this.setState({ data: response.data, dataFiltred: response.data });
        // } else console.log(response);
      }
    });
  };

  toggleScanner = () => {
    if (!this.state.entrepriseSelectionne) {
      Swal.fire({
        text: "you must select the company",
        icon: "warning",
      });

      return;
    }
    // this.state.entrepriseSelectionne &&
    this.setState({ ...this.state, scan: !this.state.scan });
  };

  scannerCodeQr = (data) => {
    // console.log(data && data.text);
    if (data) {
      this.searchRef.current.value = data.text;
      this.setState({ ...this.state, result: data.text, scan: false });
      this.searchByScanne(data.text);
    }
  };

  searchByScanne = (value) => {
    const dataF = this.context.data.filter((person) => {
      if (
        person.cin &&
        person.cin.toLowerCase().includes(value.toLowerCase())
      ) {
        return person;
      }
    });

    // this.info = dataF;

    this.setState({
      dataFiltred: dataF,
      filterText: value,
      entrepriseSelectionne: null,
      idPersonneSelectionne: dataF.length === 1 ? dataF[0].id : null,
    });

    // console.log(this.state.idEntrepriseSelectionne);
    // console.log(this.state.idPersonneSelectionne);

    this.entrepriseSelectionneRef.current = null;
  };

  handleSearch = (e) => {
    const dataF = this.context.data.filter((person) => {
      if (
        person.cin &&
        person.cin.toLowerCase().includes(e.target.value.toLowerCase())
      ) {
        return person;
      }
    });

    // this.info = dataF;

    this.setState({
      dataFiltred: dataF,
      filterText: e.target.value,
      idPersonneSelectionne: dataF.length === 1 ? dataF[0].id : null,
    });

    // console.log(e.target.value);
  };

  onSelectHandler = (e) => {
    this.setState({
      ...this.state,
      entrepriseSelectionne: e,
      idEntrepriseSelectionne: e.label.props.children[1].props.value,
    });
  };

  onConFirmEntretien = () => {
    if (this.state.dataFiltred.length !== 1) {
      Swal.fire({
        text: "you must select the target person",
        icon: "warning",
      });

      return;
    }

    if (!this.state.idEntrepriseSelectionne) {
      Swal.fire({
        text: "you must select the company",
        icon: "warning",
      });

      return;
    }

    const formData = new FormData();

    formData.append("inscription_id", this.state.idPersonneSelectionne);
    formData.append("entreprise_id", this.state.idEntrepriseSelectionne);

    const request = {
      url: "https://api.istahh1.com/api/confirmeEntretien",
      method: "POST",
      body: formData,
    };
    const dataRec = useHttp.sendRequest(request);
    console.log(dataRec);

    this.searchRef.current.value = "";
    this.setState({
      ...this.state,
      filterText: "",
      dataFiltred: [],
      entrepriseSelectionne: null,
    });

    toast("l’entretien est confirmé", {
      closeButton: true,
      progress: false,
      positionClass: "toast-top-right",
      theme: "dark",
      autoClose: 500,
    });
  };

  render() {
    const columns = [
      {
        name: "Cin",
        selector: "cin",
        sortable: true,
        width: "90px",
      },
      {
        name: "Nom",
        selector: "nom",
        sortable: true,
        width: "150px",
      },
      {
        name: "Email",
        selector: "email",
        sortable: true,
      },
      {
        name: "Telephone",
        selector: "telephone",
        sortable: true,
      },
      {
        name: "",
        cell: (row) => (
          <>
            {/* <Link
              // onClick={() => this.handleEdit(row.id)}
              to={`/listInscription/editForm/${row.id}`}
              className="btn btn-warning btn-sm"
            >
              <FontAwesomeIcon icon={faEdit} className="text-white" />
            </Link> */}
            {/* | */}
            {/* <button
              onClick={() => this.handleDelete(row.id)}
              className="btn btn-danger btn-sm"
            >
              <FontAwesomeIcon icon={faTrash} />
            </button> */}
            {/* | */}
            <Link to={`/detail/${row.id}`} className="btn btn-info btn-sm">
              <FontAwesomeIcon icon={faInfoCircle} color="white" />
            </Link>
            |
            <Link to={`/showCv/${row.id}`} className="btn btn-secondary btn-sm">
              <FontAwesomeIcon icon={faFilePdf} color="white" />
            </Link>
            |
            <Link
              to={`/inscriptionEntreprises/${row.id}`}
              className="btn btn-secondary btn-sm"
            >
              <FontAwesomeIcon icon={faBuilding} color="white" />
            </Link>
          </>
        ),
        button: true,
        width: "180px",
      },
      // {
      //     name: 'Delete',
      //     cell: row => (
      //         <button onClick={() => this.handleDelete(row.id)} className='btn btn-danger'>
      //             <FontAwesomeIcon icon={faTrash} />
      //         </button>
      //     ),
      //     button: true,
      // },
    ];

    return (
      <Fragment>
        <Navigation activeLink="listInscription" />

        {this.state.scan && this.state.entrepriseSelectionne && (
          <QrReader delay={100} onScan={this.scannerCodeQr} />
        )}
        <div className={`container px-5 ${classes.zoom}`}>
          <div className="my-5 d-flex justify-content-between align-items-center flex-wrap gap-2">
            <span className={`searchField`}>
              <input
                ref={this.searchRef}
                value={this.state.filterText}
                placeholder="Search"
                onChange={this.handleSearch}
                className={`form-control`}
              />
              {!this.state.filterText && (
                <FontAwesomeIcon
                  style={{ cursor: "pointer" }}
                  onClick={this.toggleScanner}
                  icon={faQrcode}
                  size="3x"
                />
              )}

              {this.state.filterText && (
                <FontAwesomeIcon
                  style={{ cursor: "pointer" }}
                  onClick={this.onConFirmEntretien}
                  icon={faCheckCircle}
                  size="3x"
                />
              )}
            </span>
            <div style={{ width: "20em", marginTop: "1em" }}>
              <Select
                ref={this.entrepriseSelectionneRef}
                value={this.state.entrepriseSelectionne}
                onChange={this.onSelectHandler}
                options={this.state.options}
              />
            </div>
          </div>
          <div className="d-flex"></div>
          {this.context.data.length > 0 && (
            <DataTable
              pagination
              columns={columns}
              data={
                this.state.dataFiltred.length > 0
                  ? this.state.dataFiltred
                  : this.context.data
              }
              // data={this.context.data}
              sortIcon={this.sortIcon}
              selectableRowsComponent={Checkbox}
            />
          )}
        </div>

        {this.context.data.length === 0 && (
          <Loading>
            <h5>Loading...</h5>
          </Loading>
        )}

        <ToastContainer />

        <Outlet />
      </Fragment>
      // <StoreConsumer>
      //   {async (ctx) => {
      //     // const request = {
      //     //   url: this.url + "inscriptions",
      //     // }
      //     // await ctx.fetchData(request);
      //     return (
      //     );
      //   }}
      // </StoreConsumer>
    );
  }
}

const ListInscriptionNav = () => {
  const navigate = useNavigate();
  const ctx = useContext(store);

  useEffect(() => {
    ctx.onNavigateTo("/listInscription");
    // !ctx.isAuth && navigate("/login")
    // !localStorage.getItem("isAuth") && navigate("/login")
    !sessionStorage.getItem("isAuth") && navigate("/login");
  });

  return (
    <Fragment>
      {/* {
        ctx.isAuth && <ListInscription />
      } */}
      {/* {
        localStorage.getItem("isAuth") && <ListInscription />
      } */}
      {sessionStorage.getItem("isAuth") && <ListInscription />}
    </Fragment>
  );
};

export default ListInscriptionNav;
