import { createContext, useCallback, useEffect, useState } from "react";
import { useHttpFun } from "../useHttp/useHttp";

const store = createContext({
  fetchData: () => {},
  postData: () => {},
  editData: () => {},
  confirmAuth: () => {},
  confirmAuthAdmin: () => {},
  onNavigateTo: (target) => {},
  data: [],
  isLoading: false,
  isAuth: null,
  code: null,
  codeAdmin: null,
  // navigateTo: null,
});

var dataStore;

export const StoreProvider = (props) => {
  const [data, setData] = useState([]);
  const { loading, sendRequest } = useHttpFun();
  const [auth, setAuth] = useState(false);
  // const [navigateTo, setNavigateTo] = useState();

  const confirmAuth = () => {
    setAuth(true);
    localStorage.setItem("isAuth", true);
    sessionStorage.setItem("isAuth", true);
  };

  const confirmAuthAdmin = () => {
    setAuth(true);
    localStorage.setItem("isAdmin", true);
    sessionStorage.setItem("isAdmin", true);
  };

  const onNavigateTo = (target) => {
    // setNavigateTo(target);
    sessionStorage.setItem("navigateTo", target);
    // console.log(navigateTo);
  };

  const applayData = async (dataRec) => {
    dataStore = await dataRec;

    try {
      setData([...dataStore.data]);
    } catch (e) {
      console.log(e.message);
    }
  };

  const postData = async (request, allowFetch = null) => {
    await sendRequest(request, (data) => {
      // console.log(data);
    });

    allowFetch &&
      fetchData(
        { url: "https://api.istahh1.com/api/inscriptions" },
        applayData
      );
  };

  const editData = async (request, allowFetch = false) => {
    await sendRequest(request, async (dataRec) => {
      console.log("dataRec", dataRec);
      var index;
      var inscription = await data.find((insc, i) => {
        index = i;
        return insc.id === dataRec.data.id;
      });

      allowFetch &&
        fetchData(
          { url: "https://api.istahh1.com/api/inscriptions" },
          applayData
        );
      // data.splice(index, 1, dataRec.data);
      // setData([...data]);
      // console.log("inscription", dataRec.data);
      // console.log("data", data);
    });
  };

  const fetchData = async (request, checkData = null) => {
    !checkData && (await sendRequest(request, applayData));
    checkData && (await sendRequest(request, checkData)); // checkData like applay data but from the component appellant
  };

  return (
    <store.Provider
      value={{
        fetchData: fetchData,
        postData: postData,
        editData: editData,
        confirmAuth: confirmAuth,
        confirmAuthAdmin: confirmAuthAdmin,
        onNavigateTo: onNavigateTo,
        data: data,
        isLoding: loading,
        isAuth: auth,
        code: "hakimkhabir",
        codeAdmin: "17131713",
        // navigateTo: navigateTo,
      }}
    >
      {props.children}
    </store.Provider>
  );
};

export const StoreConsumer = store.Consumer;
export default store;
