import { Link } from "react-router-dom";
import styles from "./HeaderHackathon.module.css";

const HeaderHackaton = () => {
  return (
    <div className={styles.content}>
      <ul className={styles.links}>
        <li>
          <Link to={"/hackathon/Introduction"}>Introduction</Link>
        </li>
        <li>
          <Link to={"/hackathon/ProposDuHackathon"}>A PROPS DU HACKATHON</Link>
        </li>
        <li>
          <Link to={"/hackathon/Agenda"}>AGENDA</Link>
        </li>
        <li>
          <Link to={"/hackathon/DevenirUnSponsor"}>DEVENEZ UN SPONSOR</Link>
        </li>
        <li>
          <Link to={"/hackathon/Conclusion"}>CONCLUSION</Link>
        </li>
      </ul>
    </div>
  );
};

export default HeaderHackaton;
