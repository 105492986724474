import { NavLink } from "react-router-dom";
import styles from "./Navigation.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

const Navigation = (props) => {
  const [activeLink, setactiveLink] = useState(props.activeLink);

  return (
    <nav className={styles.navs}>
      <NavLink
        className={activeLink === "listInscription" ? styles.activeLink : styles.inactiveLink}
    to={"/listInscription"}
      >
        <FontAwesomeIcon className="me-1" icon={faCircleInfo} />
        List Inscriptions
      </NavLink>
      <NavLink className={activeLink === "listEntreprises" ? styles.activeLink : styles.inactiveLink} activeClassName={styles.activeLink} to={"/listEntreprises"}>
        <FontAwesomeIcon className="me-1" icon={faBuilding} />
        List Entreprises
      </NavLink>
    </nav>
  );
};

export default Navigation;
