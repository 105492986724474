import { Fragment } from "react";
import styles from "./Intro.module.css";
import Bar from "../Bar/Bar";

const Intro = () => {
  return (
    <Fragment>
      <div className={`${styles.intro}`}>
        <div className={`${styles.title}`}>
          <img className={`${styles.logo}`} src="./images/logoOfppt.png" />
          {/* <img
            className={`${styles.backgroundImage}`}
            src="./images/cells2.jpg"
          /> */}
          <h1 className={`${styles.h1}`}>FORUM REGIONAL DE L'EMPLOI</h1>
          <span></span>
        </div>
        <Bar />
        <div className={`${styles.presentation}`}>
          <h2 className={`${styles.theme} `}>
            INNOVATION ET EXCELLENCE CATALYSEURS DE L'EMPLOYABILITé
          </h2>
          <a href="#registration-form" className={styles.doInscription}>
            faire votre inscription
          </a>
        </div>
      </div>
    </Fragment>
  );
};

export default Intro;
