import { Fragment, useState, useEffect } from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import styles from "./Missons.module.css";

const Missons = () => {
  const [slidesToShow, setSlideToShow] = useState(6);

  const onSetSlideToShow = () => {
    console.log(window.innerWidth);
    if (window.innerWidth > 1910) {
      setSlideToShow(4);
    }
    if (window.innerWidth > 1600 && window.innerWidth < 1900) {
      setSlideToShow(4);
    }
    if (window.innerWidth > 1400 && window.innerWidth < 1600) {
      setSlideToShow(3);
    }
    // if (window.innerWidth > 1100 && window.innerWidth < 1400) {
    //   setSlideToShow(3);
    // }
    if (window.innerWidth < 1100) {
      setSlideToShow(2);
    }
    if (window.innerWidth < 750) {
      setSlideToShow(1);
    }
  };

  useEffect(() => {
    onSetSlideToShow();

    window.addEventListener("resize", onSetSlideToShow);

    return () => {
      window.removeEventListener("resize", onSetSlideToShow);
    };
  }, []);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    centerMode: true,
    autoplay: true,
  };

  return (
    <Fragment>
      <div className={`${styles.slider}`} style={{ width: "100%", overflow: "hidden" }}>
        <Slider className="my-slider-class" {...settings}>
          <div>
            <img src={"./images/img1.jpg"} height={"250em"} width={"90%"} />
          </div>
          <div>
            <img src={"./images/img2.jpg"} height={"250em"} width={"90%"} />
          </div>
          <div>
            <img src={"./images/img3.jpg"} height={"250em"} width={"90%"} />
          </div>
          <div>
            <img src={"./images/img4.jpg"} height={"250em"} width={"90%"} />
          </div>
          <div>
            <img src={"./images/img5.jpg"} height={"250em"} width={"90%"} />
          </div>
          <div>
            <img src={"./images/img6.jpg"} height={"250em"} width={"90%"} />
          </div>
          <div>
            <img src={"./images/img7.jpg"} height={"250em"} width={"90%"} />
          </div>
        </Slider>
      </div>

      <br />

      <div className={`${styles.decImage}`}> 
        <p className={`${styles.para}`}>
          Découvrez en images les moments captivants et prometteurs de l'événement
          dédié aux opportunités de carrière à l'ISTA Hay Hassani I. Ces clichés
          reflètent la vivacité des échanges entre recruteurs et jeunes talents,
          mettant en lumière l'élan vers une insertion socio-professionnelle
          réussie. Les sourires confiants des participants, les échanges
          dynamiques et les rencontres fructueuses illustrent l'engagement de
          l'ISTA Hay Hassani I en faveur de l'inclusion économique et de
          l'épanouissement professionnel. Ces images témoignent de l'environnement
          stimulant offert aux visiteurs, propice aux réflexions constructives et
          aux opportunités d'avenir.
        </p>
      </div>
    </Fragment>
  );
};

export default Missons;
