import HeaderHackaton from "../header.js/Header";
import styles from "./Conclusion.module.css";

const Conclusion = () => {
  return (
    <div className={styles.body}>
      <div className={styles.animation}></div>
      <div className={styles.animation2}></div>

      <img src="/images/res.jpg" className={styles.imageSection} />
      <div className={styles.content}>
        <HeaderHackaton />
        <h1 className={styles.title}>CONCLUSION</h1>
        <h3 className={styles["sous-title"]}>
          Engagez-vous avec l’INNVA-SPHERE 2024:
        </h3>

        <p className={styles.para}>
          INNOVA -SPHERE 2024 représente une opportunité unique de s'associer à
          un événement prometteur qui impactera positivement le paysage
          technologique local. Nous espérons sincèrement vous comptez parmi les
          sponsors essentiels de cet événement. En sponsorisant le Hackathon
          INNOVA-SPHERE 2024, vous contribuerez non seulement à la réussite de
          cet événement unique mais aussi à l'avenir de l'innovation et de la
          promotion de la technologie dans notre pays et l'encouragement des
          jeunes talents engagés dans le domaine technologique. Votre soutien
          permettra de réaliser un événement marquant, inspirant pour tous les
          participants
        </p>
      </div>
    </div>
  );
};

export default Conclusion;
