import { useEffect, useState, Fragment, useRef } from "react";
import { useParams } from "react-router-dom";
import { useHttpFun } from "../../useHttp/useHttp";
import { Link } from "react-router-dom";

import DataTable from "react-data-table-component";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";

import styles from "./InscriptionEntreprises.module.css";
import Navigation from "../Navigation/Navigation";

const AdminInscriptionEntreprises = () => {
  const [entreprises, setEntreprises] = useState([]);
  const params = useParams();
  const { sendRequest } = useHttpFun();
  const [dataFiltred, setDataFiltred] = useState([]);
  const [filterText, setFilterText] = useState();

  const searchRef = useRef();

  const applayData = (dataRec) => {
    setEntreprises([...dataRec.data]);
  };

  useEffect(() => {
    const request = {
      url: `https://api.istahh1.com/api/inscriptionEntreprises/${params.id}`,
    };

    sendRequest(request, applayData);
  }, []);

  const handleSearch = (e) => {
    const dataF = entreprises.filter((entreprise) => {
      if (
        entreprise.name &&
        entreprise.name.toLowerCase().includes(e.target.value.toLowerCase())
      ) {
        return entreprise;
      }
    });

    setDataFiltred(dataF);
  };

  const columns = [
    {
      name: "logo",
      cell: (row) => (
        <Fragment>
          <img
            width={50}
            height={50}
            src={`https://api.istahh1.com/logos/${row.logo}`}
            alt={row.name}
          />
        </Fragment>
      ),
    },
    {
      name: "name",
      selector: "name",
      sortable: true,
      width: "150px",
    },
    {
      cell: (row) => (
        <Fragment>
          <Link
            to={`/adminshowEntretiens/${row.name}/${row.id}`}
            className="btn btn-secondary btn-sm"
          >
            <FontAwesomeIcon icon={faUsers} color="white" />
          </Link>
        </Fragment>
      ),
    },
  ];

  return (
    <Fragment>
      <Navigation activeLink="listEntreprises" />

      <div className="container">
        <span className={styles.searchField}>
          <input
            ref={searchRef}
            value={filterText}
            placeholder="Search"
            onChange={handleSearch}
            className={`form-control`}
          />
        </span>

        <div className={styles.dataTAble}>
          <DataTable
            pagination
            columns={columns}
            data={dataFiltred.length > 0 ? dataFiltred : entreprises}
            sortIcon={<ArrowDownward />}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default AdminInscriptionEntreprises;
