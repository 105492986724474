import { Fragment, useRef, useState } from "react";
import classes from "./AddEntreprise.module.css";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel, faShare } from "@fortawesome/free-solid-svg-icons";
import { useHttpFun } from "../useHttp/useHttp";
import Swal from "sweetalert2";

const AddEntreprise = () => {
  const navigate = useNavigate();
  const { sendRequest } = useHttpFun();
  const [isValid, setIsValid] = useState({
    nameValid: false,
    logoValid: false,
  });

  const logoRef = useRef();
  const nameRef = useRef();

  const onCancelHandler = () => {
    navigate("/adminlistEntreprises");
  };

  const checkValidation = (e) => {
    const { name } = e.target;

    if (name === "logo") {
      const file = e.target.files[0];
      const validExtensions = ["jpg", "jpeg", "png", "gif"]; // Add valid image extensions

      if (file) {
        const fileExtension = file.name.split(".").pop().toLowerCase();

        setIsValid((prevIsValid) => ({
          ...prevIsValid,
          logoValid:
            file &&
            e.target.files.length > 0 &&
            validExtensions.includes(fileExtension),
        }));
      } else {
        setIsValid((prevIsValid) => ({
          ...prevIsValid,
          logoValid: false,
        }));
      }
    } else if (name === "name") {
      setIsValid((prevIsValid) => ({
        ...prevIsValid,
        nameValid: e.target.value.trim() !== "",
      }));
    }
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("logo", logoRef.current.files[0]);
    formData.append("name", nameRef.current.value);

    const request = {
      url: "https://api.istahh1.com/api/storeEntreprise",
      method: "POST",
      body: formData,
    };

    if (!isValid.logoValid || !isValid.nameValid) {
      Swal.fire({
        text: "There's an error!",
        icon: "error",
      });

      return;
    }

    sendRequest(request, () => {
      Swal.fire({
        text: "Success",
        icon: "success",
      }).then((response) => {
        if (response.isConfirmed) {
          window.location.href = "/AdminlistEntreprises";
        }
      });
    });
    // navigate("/listEntreprises");
  };

  return (
    <Fragment>
      <div className={`${classes.backScreen}`}></div>
      <form
        onSubmit={onSubmitHandler}
        className={`${classes.addEntrepriseForm}`}
      >
        <div className={`${classes["form-group"]}`}>
          <label className={`form-label`} htmlFor="cin">
            logo
          </label>
          <input
            required
            ref={logoRef}
            onChange={checkValidation}
            type="file"
            className={`form-control ${
              isValid.logoValid ? "is-valid" : "is-invalid"
            }`}
            name="logo"
            id="logo"
          />
        </div>
        <div className={`${classes["form-group"]}`}>
          <label className={`form-label`} htmlFor="cin">
            name
          </label>
          <input
            required
            ref={nameRef}
            // onChange={handleChange}
            className={`form-control ${
              isValid.nameValid ? "is-valid" : "is-invalid"
            }`}
            name="name"
            id="name"
            onChange={checkValidation}
          />
        </div>
        <div>
          <button className="btn btn-success" style={{ width: "10em" }}>
            <FontAwesomeIcon className="me-2" icon={faShare} />
            Submit
          </button>
          &nbsp;
          <button
            type="button"
            className="btn btn-danger"
            onClick={onCancelHandler}
          >
            <FontAwesomeIcon className="me-2" icon={faCancel} />
            Cancel
          </button>
        </div>
      </form>
    </Fragment>
  );
};

export default AddEntreprise;
