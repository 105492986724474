import { useEffect, useState, Fragment, useRef } from "react";
import { useParams } from "react-router-dom";
import { useHttpFun } from "../../useHttp/useHttp";
import { Link } from "react-router-dom";

import DataTable from "react-data-table-component";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faFilePdf,
  faQrcode,
} from "@fortawesome/free-solid-svg-icons";
import QrReader from "react-qr-scanner";

import styles from "./Entretien.module.css";
import "./EntretienDataTable.css";
import Navigation from "../Navigation/Navigation";

const Entretiens = () => {
  const [entretiens, setEntretiens] = useState([]);
  const params = useParams();
  const { sendRequest } = useHttpFun();
  const [dataFiltred, setDataFiltred] = useState([]);
  const [filterText, setFilterText] = useState();
  const [scan, setScan] = useState(false);
  const [result, setResult] = useState();

  const searchRef = useRef();

  const applayData = (dataRec) => {
    setEntretiens([...dataRec.data]);
  };

  useEffect(() => {
    const request = {
      url: `https://api.istahh1.com/api/showEntretiens/${params.id}`,
    };

    sendRequest(request, applayData);
  }, []);

  const toggleScanner = () => {
    setScan(!scan);
  };

  const scannerCodeQr = (data) => {
    if (data) {
      searchRef.current.value = data.text;
      setScan(false);
      setResult(data.text);
      searchByScanne(data.text);
    }
  };

  const searchByScanne = (value) => {
    const dataF = entretiens.filter((person) => {
      if (
        person.cin &&
        person.cin.toLowerCase().includes(value.toLowerCase())
      ) {
        return person;
      }
    });

    setDataFiltred(dataF);
    setFilterText(value);
  };

  const handleSearch = (e) => {
    const dataF = entretiens.filter((person) => {
      if (
        person.cin &&
        person.cin.toLowerCase().includes(e.target.value.toLowerCase())
      ) {
        return person;
      }
    });

    setDataFiltred(dataF);
    setFilterText(e.target.value);
  };

  const columns = [
    {
      name: "Cin",
      selector: "cin",
      sortable: true,
      width: "90px",
    },
    {
      name: "Nom",
      selector: "nom",
      sortable: true,
      width: "150px",
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
    },
    {
      cell: (row) => (
        <Fragment>
          <Link to={`/showCv/${row.id}`} className="btn btn-secondary btn-sm">
            <FontAwesomeIcon icon={faFilePdf} color="white" />
          </Link>{" "}
          |
          <Link
            to={`/admininscriptionEntreprises/${row.id}`}
            className="btn btn-secondary btn-sm"
          >
            <FontAwesomeIcon icon={faBuilding} color="white" />
          </Link>
        </Fragment>
      ),
    },
  ];

  return (
    <Fragment>
      <Navigation activeLink="listEntreprises" />

      <div className="container">
        {scan && <QrReader delay={100} onScan={scannerCodeQr} />}
        <span className={styles.searchField}>
          <input
            ref={searchRef}
            value={filterText}
            placeholder="Search"
            onChange={handleSearch}
            className={`form-control`}
          />
          <FontAwesomeIcon
            style={{ cursor: "pointer" }}
            onClick={toggleScanner}
            icon={faQrcode}
            size="3x"
          />
        </span>

        {entretiens.length > 0 && (
          <div className={styles.dataTAble}>
            <DataTable
              pagination
              columns={columns}
              data={dataFiltred.length > 0 ? dataFiltred : entretiens}
              // data={this.context.data}
              sortIcon={<ArrowDownward />}
              //   selectableRowsComponent={Checkbox}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default Entretiens;
