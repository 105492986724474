import { Fragment } from "react";
import classes from "./NotFound.module.css";

const NotFound = () => {
    return (
        <Fragment>
            <div className={`${classes.notFound}`}>
                <h1>Not Found</h1>
            </div>
        </Fragment>
    )
}

export default NotFound;