import { Link } from "react-router-dom";
import styles from "./TableDeContenu.module.css";

const TableDeContenu = () => {
  return (
    <div>
      <div className={styles.head}>
        <img className={styles.imgCover} src={"/images/groupePersonnes.jpg"} />
        <div className={styles.logoTitle}>
          <img className={`${styles.logo}`} src={"/images/logoOfppt.png"} />
          <h1>Table de contenu</h1>
        </div>
      </div>

      <div className={styles.head2}>
        <span></span>
        <div className={styles.logoTitle}>
          <img className={`${styles.logo}`} src={"/images/logoOfppt.png"} />
          <h1>Table de contenu</h1>
        </div>
      </div>

      <div className={styles.bottom}>
        <ul className={styles.plan}>
          <li>
            <Link to={"/hackathon/Introduction"}>
              <span>1</span>INTRODUCTION
            </Link>
          </li>
          <li>
            <Link to="/hackathon/ProposDuHackathon">
              <span>2</span>A PROPS DU HACKATHON
            </Link>
          </li>
          <li>
            <Link to={"/hackathon/Agenda"}>
              <span>3</span>AGENDA
            </Link>
          </li>
          <li>
            <Link to={"/hackathon/DevenirUnSponsor"}>
              <span>4</span>DEVENEZ UN SPONSOR
            </Link>
          </li>
          <li>
            <Link to={"/hackathon/Conclusion"}>
              <span>5</span>CONCLUSION
            </Link>
          </li>
        </ul>
        <img
          src={"/images/gens-affaires-travaillant-ordinateur-portable.jpg"}
        />
      </div>
    </div>
  );
};

export default TableDeContenu;
