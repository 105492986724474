import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css"; // Import Swiper's CSS
import "swiper/css/autoplay"; // Import Swiper's Autoplay CSS
import styles from  "./EntrepriseCarousel.module.css"; // Import module CSS

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

import { EffectCoverflow, Pagination, Autoplay } from 'swiper/modules';

import slideImage1 from "./assets/img_1.jpg";
import slideImage2 from "./assets/img_2.jpg";
import slideImage3 from "./assets/img_3.jpg";
import slideImage4 from "./assets/img_4.png";
import slideImage5 from "./assets/img_5.png";
import slideImage6 from "./assets/img_6.jpg";
import slideImage7 from "./assets/img_7.jpeg";
import slideImage8 from "./assets/img_8.png";
import slideImage9 from "./assets/img_9.png";
import slideImage10 from "./assets/img_10.png";
import slideImage11 from "./assets/img_11.jpg";
import slideImage12 from "./assets/img_12.png";
import slideImage13 from "./assets/img_13.jpg";
import slideImage14 from "./assets/img_14.png";
import slideImage15 from "./assets/img_15.jpeg";
import slideImage16 from "./assets/img_16.png";
import slideImage17 from "./assets/img_17.jpg";
import slideImage18 from "./assets/img_18.jpg";
import slideImage19 from "./assets/img_19.jpg";
import slideImage20 from "./assets/img_20.jpg";

const EntrepriseCarousel = () => {
  return (
    <div className={styles.ctr}>
      <h3>nos partenaires</h3>
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        loop={true}
        autoplay={
          true
        }
        centeredSlides={true}
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
          autoplay:{
            delay: 500,
          }
        }}
        pagination={true}
        modules={[EffectCoverflow, Pagination, Autoplay]}
        className={`mySwiper ${styles.swiper}`}
      >
        <SwiperSlide className={styles["swiper-slide"]}>
          <div>
            <img src={slideImage1} />
          </div>
        </SwiperSlide>
        <SwiperSlide className={styles["swiper-slide"]}>
          <div>
            <img src={slideImage2} />
          </div>
        </SwiperSlide>
        <SwiperSlide className={styles["swiper-slide"]}>
          <div>
            <img src={slideImage3}/>
          </div>
        </SwiperSlide>
        <SwiperSlide className={styles["swiper-slide"]}>
          <div>
            <img src={slideImage4} />
          </div>
        </SwiperSlide>
        <SwiperSlide className={styles["swiper-slide"]}>
          <div>
            <img src={slideImage5} />
          </div>
        </SwiperSlide>
        <SwiperSlide className={styles["swiper-slide"]}>
          <div>
            <img src={slideImage6} />
          </div>
        </SwiperSlide>
        <SwiperSlide className={styles["swiper-slide"]}>
          <div>
            <img src={slideImage7} />
          </div>
        </SwiperSlide>
        <SwiperSlide className={styles["swiper-slide"]}>
          <div>
            <img src={slideImage8} />
          </div>
        </SwiperSlide>
        <SwiperSlide className={styles["swiper-slide"]}>
          <div>
            <img src={slideImage9} />
          </div>
        </SwiperSlide>
        <SwiperSlide className={styles["swiper-slide"]}>
          <div>
            <img src={slideImage10} />
          </div>
        </SwiperSlide>
        <SwiperSlide className={styles["swiper-slide"]}>
          <div>
            <img src={slideImage11} />
          </div>
        </SwiperSlide>
        <SwiperSlide className={styles["swiper-slide"]}>
          <div>
            <img src={slideImage12} />
          </div>
        </SwiperSlide>
        <SwiperSlide className={styles["swiper-slide"]}>
          <div>
            <img src={slideImage13} />
          </div>
        </SwiperSlide>
        <SwiperSlide className={styles["swiper-slide"]}>
          <div>
            <img src={slideImage14} />
          </div>
        </SwiperSlide>
        <SwiperSlide className={styles["swiper-slide"]}>
          <div>
            <img src={slideImage15} />
          </div>
        </SwiperSlide>
        <SwiperSlide className={styles["swiper-slide"]}>
          <div>
            <img src={slideImage16} />
          </div>
        </SwiperSlide>
        <SwiperSlide className={styles["swiper-slide"]}>
          <div>
            <img src={slideImage17} />
          </div>
        </SwiperSlide>
        <SwiperSlide className={styles["swiper-slide"]}>
          <div>
            <img src={slideImage18} />
          </div>
        </SwiperSlide>
        <SwiperSlide className={styles["swiper-slide"]}>
          <div>
            <img src={slideImage19} />
          </div>
        </SwiperSlide>
        <SwiperSlide className={styles["swiper-slide"]}>
          <div>
            <img src={slideImage20} />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default EntrepriseCarousel;
