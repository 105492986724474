import HeaderHackaton from "../header.js/Header";
import styles from "./Agenda.module.css";

const Agenda = () => {
  return (
    <div className={styles.body}>
      <div className={styles.animation}></div>
      <div className={styles.animation2}></div>
      <img src="/images/agenda.jpg" className={styles.imageSection} />
      <div>
        <HeaderHackaton />
        <h1 className={styles.title}>AGENDA</h1>
        <h3 className={styles["sous-title"]}>Aperçu de l’évènemen</h3>

        <p className={styles.para}>
          <div className={styles.date}>
            <span className={styles.time}>10h00</span> à <span className={styles.time}>10h30</span>: Accueil et mot d’ouverture
          </div>
          <div className={styles.date}>
            <span className={styles.time}>10h00</span> à <span className={styles.time}>17h00</span> :Développement et préparation des
            présentations
          </div>
          <div className={styles.date}>
            <span className={styles.time}>17h00</span> : Présentation des projets et délibération du jury
          </div>
        </p>
      </div>
    </div>
  );
};

export default Agenda;
