import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import DataTable from "react-data-table-component";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Checkbox from "@material-ui/core/Checkbox";
import { useHttpFun } from "../../useHttp/useHttp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faPlusCircle,
  faTrashAlt,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { Outlet, useNavigate } from "react-router-dom";
import store from "../../Store/Store";
import Swal from "sweetalert2";
import Loading from "../../Loading/Loading";
import AdminNavigation from "../Navigation/Navigation";

const ListEntreprises = () => {
  const [data, setData] = useState([]);
  const [dataF, setDataF] = useState([]);
  const navigate = useNavigate();
  const ctx = useContext(store);

  const { loading, sendRequest } = useHttpFun();

  const applyData = (dataRec) => {
    setData([...dataRec.data]);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const request = { url: "https://api.istahh1.com/api/entreprises" };
        await sendRequest(request, applyData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [sendRequest]);

  const handleDelete = (id) => {
    Swal.fire({
      text: "Are You sure",
      icon: "warning",
      showCancelButton: true,
    }).then((response) => {
      if (response.isConfirmed) {
        const request = {
          url: `https://api.istahh1.com/api/deleteEntreprise/${id}`,
          method: "POST",
        };
        sendRequest(request);
        sendRequest(
          { url: "https://api.istahh1.com/api/entreprises" },
          applyData
        );
      }
    });
  };

  const handleAddNew = () => {
    navigate("addEntreprise");
  };

  const showEntretiens = (entreprise, id) => {
    navigate(`/adminshowEntretiens/${entreprise}/${id}`);
  };

  const columns = [
    {
      name: "logo",
      cell: (row) => (
        <Fragment>
          <img
            width={50}
            height={50}
            src={`https://api.istahh1.com/logos/${row.logo}`}
            alt={row.name}
          />
        </Fragment>
      ),
    },
    {
      name: "name",
      selector: "name",
      sortable: true,
    },
    {
      name: "nombre d'entretien",
      selector: "nombreEntretien",
      sortable: true,
    },
    {
      cell: (row) => (
        <Fragment>
          <button
            onClick={() => handleDelete(row.id)}
            className="btn btn-danger btn-sm"
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </button>
          &nbsp;
          <button
            onClick={() => showEntretiens(row.name, row.id)}
            className="btn btn-info btn-sm text-white"
          >
            <FontAwesomeIcon icon={faUsers} />
          </button>
        </Fragment>
      ),
    },
  ];

  const handleSearch = (e) => {
    const dataF = data.filter((enterprise) => {
      if (
        enterprise.name &&
        enterprise.name.toLowerCase().includes(e.target.value.toLowerCase())
      ) {
        return enterprise;
      }
    });

    setDataF([...dataF]);
  };

  return (
    <Fragment>
      <AdminNavigation activeLink="listEntreprises" />

      <div className={`container px-5`}>
        <div className="my-5">
          {/* <label>Search</label> */}
          <span
            className={`searchField`}
            style={{ justifyContent: "space-around" }}
          >
            <input
              placeholder="Search..."
              onChange={handleSearch}
              className="form-control"
            />
            <button onClick={handleAddNew} className="btn btn-success">
              <FontAwesomeIcon icon={faPlusCircle} />
            </button>
          </span>
        </div>
        {data.length === 0 && (
          <Loading>
            <h5>Loading...</h5>
          </Loading>
        )}
        {data.length > 0 && (
          <DataTable
            columns={columns}
            data={dataF.length > 0 ? dataF : data}
            pagination
            sortIcon={<ArrowDownward />}
            selectableRowsComponent={Checkbox}
          />
        )}
      </div>

      <Outlet />
    </Fragment>
  );
};

const AdminListEntreprises = () => {
  const navigate = useNavigate();
  const ctx = useContext(store);

  useEffect(() => {
    ctx.onNavigateTo("/AdminlistEntreprises");
    // !ctx.isAuth && navigate("/login")
    // !localStorage.getItem("isAuth") && navigate("/login")
    !sessionStorage.getItem("isAdmin") && navigate("/Adminlogin");
  });

  return (
    <Fragment>
      {/* {
        ctx.isAuth && <ListInscription />
      } */}
      {/* {
        localStorage.getItem("isAuth") && <ListInscription />
      } */}
      {sessionStorage.getItem("isAdmin") && <ListEntreprises />}
    </Fragment>
  );
};

export default AdminListEntreprises;
// export default ListEntreprises;
