import { faQrcode, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./DetailInscription.module.css";
import { useEffect, useContext, Fragment, useState, useRef } from "react";
import store from "../Store/Store";
import { useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import QRCode from "react-qr-code";
import Loading from "../Loading/Loading";

const DetailInscription = () => {
  const ctx = useContext(store);
  const params = useParams();
  const infoRef = useRef();
  const [inscription, setInscription] = useState({});
  const [size, setSize] = useState(300);

  window.addEventListener("resize", () => {
    setSize(window.innerWidth < 500 ? 200 : 300);
  });

  useEffect(() => {
    const request = {
      url: `https://api.istahh1.com/api/inscription/${params.id}`,
    };
    ctx.fetchData(request, applayData);
  }, []);

  const applayData = (dataRec) => {
    console.log(dataRec);
    setInscription(dataRec.data);
  };

  const Print = () => {
    let printContents = document.getElementById("printablediv").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  };

  return (
    <Fragment>
      {/* <video className={styles.videoBack} src={"/videos/videoBack.mp4"} loop={true} autoPlay={true} /> */}
      {/* <div className={styles.videoBack}>
        <video
          className={styles.videoBack}
          src={"/videos/videoBack.mp4"}
          autoPlay={true}
          loop={true}
        />
      </div> */}

      <div className={`${styles.backImage}`} />

      <p className={styles.imprimerText}>
        Veuillez imprimer votre inscription.
      </p>

      <div id="printablediv" className={`${styles.cordonnee}`}>
        <div className={`${styles.babel}`} />
        {/* <div className={`d-flex align-items-center`}>
          <FontAwesomeIcon icon={faUserCircle} size="3x" />
          <h1>{inscription.nom} {inscription.prenom}</h1>
        </div> */}

        <div className={`${styles.info}`} ref={infoRef}>
          <div className={`text-center mt-5 ${styles.qrCode}`}>
            {/* <FontAwesomeIcon icon={faQrcode} size="5x" /> */}
            <QRCode
              bgColor="transparent"
              level="H"
              size={size}
              value={inscription.cin ? inscription.cin : ""}
            />
          </div>
          <div className={`${styles.champ} mt-5`}>
            <label>CIN: </label>
            <p>
              <b>{inscription.cin}</b>
            </p>
          </div>
          <div className={`${styles.champ}`}>
            <label>Nom et Prénom: </label>
            <p>
              <b>
                {inscription.nom} {inscription.prenom}
              </b>
            </p>
          </div>
          <div className={`${styles.champ}`}>
            <label>EMAIL: </label>
            <p>
              <b>{inscription.email}</b>
            </p>
          </div>
          <div className={`${styles.champ}`}>
            <label>DATE DE NAISSANCE: </label>
            <p>
              <b>{inscription.dateDeNaissance}</b>
            </p>
          </div>
          {inscription.type && (
            <div className={`${styles.champ}`}>
              <label>TYPE: </label>
              <p>
                <b>{inscription.type}</b>
              </p>
            </div>
          )}
          {!inscription.type && (
            <div className={`${styles.champ}`}>
              <label>TYPE: </label>
              <p>
                <b>{inscription.diplome ? "lauréats" : "stagiaire"}</b>
              </p>
            </div>
          )}
          {inscription.diplome && (
            <div className={`${styles.champ}`}>
              <label>DIPLOME: </label>
              <p>
                <b>{inscription.diplome}</b>
              </p>
            </div>
          )}
          {inscription.anneeDobtention && (
            <div className={`${styles.champ}`}>
              <label>ANNEE D'OBTENTION: </label>
              <p>
                <b>{inscription.anneeDobtention}</b>
              </p>
            </div>
          )}
          <div className={`${styles.champ}`}>
            <label>INSTITUT: </label>
            <p>
              <b>{inscription.institue}</b>
            </p>
          </div>
          <div className={`${styles.champ}`}>
            <label>TELEPHONE: </label>
            <p>
              <b>{inscription.telephone}</b>
            </p>
          </div>
        </div>

        {/* <div className={`mt-4 ${styles.btn}`}>
          <button onClick={Print}>IMPRIME</button>
          <button>EDITER</button>
        </div> */}
        <ReactToPrint
          content={() => infoRef.current}
          trigger={() => <button className={styles.btn}>print</button>}
        />
      </div>
    </Fragment>
  );
};

export default DetailInscription;
