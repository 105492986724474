import { useCallback } from "react";
import {  useState } from "react";

export const useHttpFun = () => {
  const [loading, setLoading] = useState(false);

  const sendRequest = useCallback(async (request, applayData = () => {}) => {
    setLoading(true);

    const response = await fetch(request.url, {
      method: request.method ? request.method : "GET",
      body: request.body instanceof FormData ? request.body : JSON.stringify(request.body),
      // headers: request.headers ? request.headers : null,
    });

    const data = await response.json();

    await applayData(data);
    setLoading(false);

  }, []);
  return {
    loading,
    sendRequest,
  };
};


// class useHttp {
//     static async sendRequest(request) {
//       try {
//         const response = await fetch(request.url, {
//             method: request.method ? request.method : "GET",
//             body: request.body ? JSON.stringify(request.body) : null,
//             headers: request.headers ? request.headers : {}
//         });

//         const data = await response.json();
        
//         return data;

//       } catch (error) {
//         console.error('Error fetching data: ', error);
//         throw error;
//       }
//     }
//   } 
  

// export default useHttp;

class useHttp {
  static async sendRequest(request) {
    try {
      // const headers = new Headers();

      // if (request.body instanceof FormData) {
      //   // If the request body is FormData, set appropriate headers
      //   headers.append('Content-Type', 'multipart/form-data');
      //   // headers.append("mode", "no-cors");
      // } else {
      //   // For other request types (e.g., JSON), set JSON content type
      //   headers.append('Content-Type', 'application/json');
      // }

      const response = await fetch(request.url, {
        method: request.method ? request.method : 'GET',
        body: request.body instanceof FormData ? request.body : JSON.stringify(request.body),
        // headers: request.headers,
      });

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
    }
  }
}

export default useHttp; 
