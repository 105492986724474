import styles from "./Hackathon.module.css";
import { Link } from "react-router-dom";

const Hackathon = () => {
  return (
    <div className={styles.body}>
      
      <div className={styles.animation}></div>
      <div className={styles.animation2}></div>

      <div className={`${styles.innova}`}>
        <span className={``}>
          <img className={`${styles.logo}`} src={"/images/logoOfppt.png"} />
          <div className={styles.titre}>
            <h1>INNOVA-SHPERE 2024</h1>
            <h2>Hackathon de l'innovation Technologique</h2>
          </div>
        </span>

        <img
          className={`${styles.imgHachathon}`}
          src="/images/hackathon1.jpg"
        />
      </div>

      <div className={`${styles.innova2}`}>
        {/* <div className={styles.spacer}></div> */}
        <div>
          <img className={`${styles.logo}`} src={"/images/logoOfppt.png"} />
          <div className={styles.titre}>
            <h1>INNOVA-SHPERE 2024</h1>
            <h2>Hackathon de l'innovation Technologique</h2>
          </div>
        </div>
      </div>

      <span className={styles.description}>
        <p>
          Bienvenue à Innova-Sphère 2024, le hackathon de référence pour
          l'innovation technologique ! Cet événement promet d'être une rencontre
          passionnante entre des esprits brillants, des innovateurs et des
          passionnés de technologie du monde entier. Organisé par l'estimée
          association Dalil et hébergé au prestigieux Ofppt Hay Hassani 1 à
          Casablanca, Innova-Sphère 2024 s'engage à repousser les limites de
          l'innovation et de la créativité.
        </p>
        <span></span>
      </span>

      <span className={styles.description}>
        <span></span>
        <p>
          Notre mission est de favoriser la collaboration, la créativité et les
          compétences en résolution de problèmes parmi les participants alors
          qu'ils s'attaquent à des défis du monde réel en utilisant des
          technologies de pointe. Que vous soyez un développeur chevronné, un
          entrepreneur en herbe ou simplement passionné par l'impact positif de
          l'innovation, ce hackathon offre une opportunité unique de mettre en
          valeur vos talents et de promouvoir le changement.
        </p>
      </span>

      <span className={styles.description}>
        <p>
          Sous la direction experte de notre comité d'organisation dévoué,
          dirigé par Mme. Abidi, Responsable du Centre de Carrière à ISTA HH1,
          M. Rachdi, Président de l'association Dalil, et M. El Khabir,
          Responsable du CAD à ISTA HH, les participants auront accès à un
          mentorat précieux et un soutien tout au long de l'événement.
        </p>
        <span></span>
      </span>

      <span className={styles.description}>
        <span></span>
        <p>
          Rejoignez-nous pour une expérience inoubliable, riche en
          collaboration, en innovation et en opportunités de faire une
          différence. Nous avons hâte de vous accueillir à Innova-Sphère 2024 et
          de libérer tout le potentiel de la technologie pour façonner un avenir
          meilleur. À très bientôt !
        </p>
      </span>

      <div className={styles.info}>
        <div>
          <img
            className={styles.imgArabStyle}
            src="/images/contexte-programmation.jpg"
          />
        </div>
        <div>
          <h1>Rejoignez nous dans cet évènement, devenez sponsor</h1>
          <div className={styles.date}>
            <span>
              <h4>Date de l'evenement</h4>
              <p>Samedi 02 Mars, 2024</p>
            </span>
            <span>
              <p>
                institue Specialitse de technoloqgie appliquee hay hassani 1,
                casablanca
              </p>
              <p>
                <b>Tel:</b> + 212 608-85 51 07{" "}
              </p>
            </span>
          </div>
          <span>
            <Link className={styles.voirDetail} to="plan">Voir les details</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Hackathon;
