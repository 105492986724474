import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
  memo,
  useContext,
} from "react";
import classes from "./EditFormStyle.module.css";
import Swal from "sweetalert2";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useHttpFun } from "../useHttp/useHttp";
import store from "../Store/Store";
import Loading from "../Loading/Loading";
import InscriptionEntreprises from "../InscriptionEntreprises/InscriptionEntreprises";

const getLast5Years = () => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = 0; i < 5; i++) {
    years.push(currentYear - i);
  }
  return years;
};

const EditForm = (props) => {
  const { loading, sendRequest } = useHttpFun();
  const [data, setData] = useState({});
  const [inscription, setInscription] = useState({
    cin: "",
    nom: "",
    prenom: "",
    email: "",
    dateDeNaissance: "",
    telephone: "",
    type: "",
    diplome: "",
    anneeDobtention: "",
    institue: "",
  });
  const [formKey, setFormKey] = useState(0);
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const ctx = useContext(store);
  const [yearsDobtentionDisponible, setYearsDobtentionDisponible] = useState(
    []
  );
  const [autreEtablissement, setAutreEtablisement] = useState(false);

  useEffect(() => {
    document.getElementById("institue").addEventListener("change", (e) => {
      console.log(e.target.value);
      if (e.target.value === "autre") {
        setAutreEtablisement(true);
      }
    });
  }, []);

  const [formErrors, setFormErrors] = useState({
    cin: false,
    nom: false,
    prenom: false,
    email: false,
    dateDeNaissance: false,
    telephone: false,
    type: false,
    diplome: false,
    anneeDobtention: false,
    institue: false,
  });

  // const cinRef = useRef();
  // const nomRef = useRef();
  // const prenomRef = useRef();
  // const emailRef = useRef();
  // const dateDeNaissanceRef = useRef();
  // const telephoneRef = useRef();
  // const diplomeRef = useRef();
  // const anneeDobtentionRef = useRef();
  // const institueRef = useRef();

  useEffect(() => {
    // Set the state with the array of the last 5 years
    setYearsDobtentionDisponible([...getLast5Years()]);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const request = {
        url: `https://api.istahh1.com/api/inscription/${params.id}`,
      };

      sendRequest(request, applayData);
    };

    fetchData();
  }, [sendRequest]);

  const validateField = (fieldName, value) => {
    if (value.trim() === "") {
      setFormErrors((prevErrors) => ({ ...prevErrors, [fieldName]: true }));
    } else {
      setFormErrors((prevErrors) => ({ ...prevErrors, [fieldName]: false }));
    }
  };

  const applayData = (dataRec) => {
    // console.log({
    //     "message": dataRec.message,
    //     "status": dataRec.status,
    // });
    console.log(dataRec);

    console.log(dataRec);

    // cinRef.current.value = dataRec.data.cin;
    // nomRef.current.value = dataRec.data.nom;
    // prenomRef.current.value = dataRec.data.prenom;
    // emailRef.current.value = dataRec.data.email;
    // dateDeNaissanceRef.current.value = dataRec.data.dateDeNaissance;
    // telephoneRef.current.value = dataRec.data.telephone;
    // diplomeRef.current.value = dataRec.data.diplome;
    // anneeDobtentionRef.current.value = dataRec.data.anneeDobtention;
    // institueRef.current.value = dataRec.data.institue;

    // setInscription({
    //   cin: cinRef.current.value,
    //   nom: nomRef.current.value,
    //   prenom: prenomRef.current.value,
    //   email: emailRef.current.value,
    //   dateDeNaissance: dateDeNaissanceRef.current.value,
    //   telephone: telephoneRef.current.value,
    //   diplome: diplomeRef.current.value,
    //   anneeDobtention: anneeDobtentionRef.current.value,
    //   institue: institueRef.current.value,
    // });

    setInscription({
      cin: dataRec.data.cin,
      nom: dataRec.data.nom,
      prenom: dataRec.data.prenom,
      email: dataRec.data.email,
      dateDeNaissance: dataRec.data.dateDeNaissance,
      telephone: dataRec.data.telephone,
      type: dataRec.data.type ? dataRec.data.type : "lea",
      diplome: dataRec.data.diplome,
      anneeDobtention: dataRec.data.anneeDobtention,
      institue: dataRec.data.institue,
    });
  };

  const handleCancel = () => {
    Swal.fire({
      icon: "warning",
      title: "warning",
      text: "Are you sure",
      showCancelButton: true,
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    }).then((response) => {
      if (response.isConfirmed) {
        // window.location.pathname = "/";
        // window.location.back();
        navigate("../");
      }
    });
  };

  const handleClear = () => {
    Swal.fire({
      icon: "warning",
      title: "warning",
      text: "Are you sure",
      showCancelButton: true,
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    }).then((response) => {
      if (response.isConfirmed) {
        // cinRef.current.value = "";
        // nomRef.current.value = "";
        // prenomRef.current.value = "";
        // emailRef.current.value = "";
        // dateDeNaissanceRef.current.value = "";
        // telephoneRef.current.value = "";
        // diplomeRef.current.value = "";
        // anneeDobtentionRef.current.value = "";
        // institueRef.current.value = "";
        setInscription({});
      }
    });
  };

  // const handleChange = (e) => {
  //   setInscription({
  //     cin: cinRef.current.value,
  //     nom: nomRef.current.value,
  //     prenom: prenomRef.current.value,
  //     email: emailRef.current.value,
  //     dateDeNaissance: dateDeNaissanceRef.current.value,
  //     telephone: telephoneRef.current.value,
  //     diplome: diplomeRef.current.value,
  //     anneeDobtention: anneeDobtentionRef.current.value,
  //     institue: institueRef.current.value,
  //   });
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setInscription({ ...inscription, [name]: value });
    validateField(name, value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requiredFields = [
      "cin",
      "nom",
      "prenom",
      "email",
      "dateDeNaissance",
      "telephone",
      "diplome",
      "anneeDobtention",
      "institue",
    ];

    let isValid = true;

    requiredFields.forEach((field) => {
      if (inscription[field]?.trim() === "") {
        setFormErrors((prevErrors) => ({ ...prevErrors, [field]: true }));
        isValid = false;
      }
    });

    // console.log({
    //   cin: inscription.cin,
    //   nom: inscription.nom,
    //   prenom: inscription.prenom,
    //   email: inscription.email,
    //   dateDeNaissance: inscription.dateDeNaissance,
    //   telephone: inscription.telephone,
    //   diplome: inscription.diplome,
    //   anneeDobtention: inscription.anneeDobtention,
    //   institue: inscription.institue,
    // })

    if (isValid) {
      const formData = new FormData();

      formData.append("cin", inscription.cin);
      formData.append("nom", inscription.nom);
      formData.append("prenom", inscription.prenom);
      formData.append("email", inscription.email);
      formData.append("dateDeNaissance", inscription.dateDeNaissance);
      formData.append("telephone", inscription.telephone);
      formData.append("diplome", inscription.diplome);
      formData.append("anneeDobtention", inscription.anneeDobtention);
      formData.append("institue", inscription.institue);

      const request = {
        url: `https://api.istahh1.com/api/editInscription/${params.id}`,
        method: "POST",
        body: formData,
        // body: {
        //   cin: inscription.cin,
        //   nom: inscription.nom,
        //   prenom: inscription.prenom,
        //   email: inscription.email,
        //   dateDeNaissance: inscription.dateDeNaissance,
        //   telephone: inscription.telephone,
        //   diplome: inscription.diplome,
        //   anneeDobtention: inscription.anneeDobtention,
        //   institue: inscription.institue,
        // },
        // headers: {
        //   "Content-Type": "application/json",
        // },
      };

      Swal.fire({
        icon: "success",
        title: "Success",
        text: "is updated",
      }).then((response) => {
        ctx.editData(request, true);
        ctx.fetchData({ url: "https://api.istahh1.com/api/inscriptions" });
        response.isConfirmed && navigate("/adminListInscription");

        // fetch(request.url, {body: JSON.stringify(request.body), method: request.method, headers: request.headers})
        // .then(() => {
        //   window.location.href = "/listInscription";
        // });
      });
    } else {
      Swal.fire({
        text: "There's an error",
        icon: "error",
      });
    }
  };

  return (
    <Fragment>
      <div className={classes.backScreen}></div>
      <div className="container mt-5 px-5">
        <form
          method="POST"
          className={`${classes.editForm}`}
          onSubmit={handleSubmit}
        >
          <div className={`${classes["form-group"]}`}>
            <label className={`form-label`} htmlFor="cin">
              cin
            </label>
            <input
              required
              value={inscription.cin}
              onChange={handleChange}
              className={`form-control ${
                inscription.cin ? "is-valid" : "is-invalid"
              }`}
              name="cin"
              id="cin"
            />
          </div>
          <div className={`${classes["form-group"]}`}>
            <label className={`form-label`} htmlFor="nom">
              Nom
            </label>
            <input
              required
              value={inscription.nom}
              onChange={handleChange}
              className={`form-control ${
                inscription.nom ? "is-valid" : "is-invalid"
              }`}
              name="nom"
              id="nom"
              type="text"
            />
          </div>
          <div className={`${classes["form-group"]}`}>
            <label className={`form-label`} htmlFor="prenom">
              Prenom
            </label>
            <input
              required
              value={inscription.prenom}
              onChange={handleChange}
              className={`form-control ${
                inscription.prenom ? "is-valid" : "is-invalid"
              }`}
              name="prenom"
              id="prenom"
            />
          </div>
          <div className={`${classes["form-group"]}`}>
            <label className={`form-label`} htmlFor="email">
              Email
            </label>
            <input
              required
              value={inscription.email}
              onChange={handleChange}
              className={`form-control ${
                inscription.email ? "is-valid" : "is-invalid"
              }`}
              name="email"
              id="email"
              type="email"
            />
          </div>
          <div className={`${classes["form-group"]}`}>
            <label className={`form-label`} htmlFor="dateDeNaissance">
              Date De Naissance
            </label>
            <input
              required
              value={inscription.dateDeNaissance}
              onChange={handleChange}
              className={`form-control ${
                inscription.dateDeNaissance ? "is-valid" : "is-invalid"
              }`}
              name="dateDeNaissance"
              id="dateDeNaissance"
              type="date"
            />
          </div>
          <div className={`${classes["form-group"]}`}>
            <label className={`form-label`} htmlFor="telephone">
              Telephone
            </label>
            <input
              required
              value={inscription.telephone}
              onChange={handleChange}
              className={`form-control ${
                inscription.telephone ? "is-valid" : "is-invalid"
              }`}
              name="telephone"
              id="telephone"
              type="tel"
            />
          </div>
          <div className={`${classes["form-group"]}`}>
            <label className={`form-label`} htmlFor="type">
              Type
            </label>
            <select
              required
              value={inscription.diplome ? "lauréats" : "stagiaire"}
              onChange={handleChange}
              className={`form-select ${
                inscription.type ? "is-valid" : "is-invalid"
              }`}
              name="type"
              id="type"
            >
              <option value={""}>Lauréats ou Stagiaire</option>
              <option value={"stagiaire"}>Stagiaire</option>
              <option value={"lauréats"}>Lauréats</option>
            </select>
            {/* <input
              required
              value={inscription.type}
              onChange={handleChange}
              className={`form-control ${
                inscription.type ? "is-valid" : "is-invalid"
              }`}
              name="type"
              id="type"
              type="text"
            /> */}
          </div>
          {inscription.type === "lauréats" && (
            <div className={`${classes["form-group"]}`}>
              <label className={`form-label`} htmlFor="diplome">
                Diplome
              </label>
              <input
                required
                value={inscription.diplome}
                onChange={handleChange}
                className={`form-control ${
                  inscription.diplome ? "is-valid" : "is-invalid"
                }`}
                name="diplome"
                id="diplome"
              />
            </div>
          )}
          {inscription.type === "lauréats" && (
            <div className={`${classes["form-group"]}`}>
              <label className={`form-label`} htmlFor="anneeDobtention">
                Annee D'obtention
              </label>
              <select
                required
                value={inscription.anneeDobtention}
                onChange={handleChange}
                className={`form-select ${
                  inscription.anneeDobtention ? "is-valid" : "is-invalid"
                }`}
              >
                <option value={""}>Sélectionner L'année d'obtention </option>
                {yearsDobtentionDisponible &&
                  yearsDobtentionDisponible.map((year, i) => (
                    <option key={i} value={year}>
                      {year}
                    </option>
                  ))}
              </select>
              {/* <input
                required
                value={inscription.anneeDobtention}
                onChange={handleChange}
                className={`form-control ${
                  inscription.anneeDobtention ? "is-valid" : "is-invalid"
                }`}
                name="anneeDobtention"
                id="anneeDobtention"
              /> */}
            </div>
          )}
          <div className={`${classes["form-group"]}`}>
            <label className={`form-label`} htmlFor="institue">
              institut
            </label>
            {!autreEtablissement && (
              <select
                required
                value={inscription.institue}
                onChange={handleChange}
                className={`form-select ${
                  inscription.institue ? "is-valid" : "is-invalid"
                }`}
                name="institue"
                id="institue"
              >
                <option value={""}>Choisir un institut</option>
                <option
                  value={
                    "Institut Spécialisé du Technologie Appliquée hey hassani 1"
                  }
                >
                  Institut Spécialisé du Technologie Appliquée hey hassani 1
                </option>
                <option
                  value={
                    "Institut Spécialisé du Bâtiment et des Travaux Publics Hay Errahma Casablanca "
                  }
                >
                  Institut Spécialisé du Bâtiment et des Travaux Publics Hay
                  Errahma Casablanca
                </option>
                <option
                  value={
                    "Institut Spécialisé de Technologie Appliquée Polo Casablanca"
                  }
                >
                  Institut Spécialisé de Technologie Appliquée Polo Casablanca
                </option>
                <option
                  value={
                    "Institut Spécialisé de Technologie Appliquée Lissasfa Casablanca"
                  }
                >
                  Institut Spécialisé de Technologie Appliquée Lissasfa
                  Casablanca
                </option>
                <option
                  value={
                    "Institut Spécialisé de Technologie Appliquée Hay Hassani 2 Casablanca"
                  }
                >
                  Institut Spécialisé de Technologie Appliquée Hay Hassani 2
                  Casablanca
                </option>
                <option
                  value={
                    "Institut Spécialisé de Technologie Appliquée Génie Mécanique Casablanca"
                  }
                >
                  Institut Spécialisé de Technologie Appliquée Génie Mécanique
                  Casablanca
                </option>
                <option
                  value={
                    "Centre Socio-Educatif et d’insertion des Jeunes Madinat Arrahma Ii  Nouaceur"
                  }
                >
                  Centre Socio-Educatif et d’insertion des Jeunes Madinat
                  Arrahma Ii Nouaceur
                </option>
                <option
                  value={
                    "iInstitut Spécialisé de Technologie Appliquée de Confection Sidi Maârouf Casablanca "
                  }
                >
                  Institut Spécialisé de Technologie Appliquée de Confection
                  Sidi Maârouf Casablanca
                </option>
                <option value={"autre"}>autre ...</option>
              </select>
            )}
            {autreEtablissement && (
              <input
                required
                value={inscription.institue}
                onChange={handleChange}
                className={`form-control ${
                  inscription.institue ? "is-valid" : "is-invalid"
                }`}
                name="institue"
                id="institue"
              />
            )}
          </div>
          <div>
            <button className={`btn btn-success`}>Submit</button>|
            <button
              type="button"
              onClick={handleCancel}
              className={`btn btn-danger`}
            >
              Cancel
            </button>
            |
            <button
              type="button"
              onClick={handleClear}
              className={`btn btn-warning text-white`}
            >
              Clear
            </button>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default memo(EditForm);
