import styled, { keyframes } from "styled-components";

const animate = keyframes`
0% {transform: rotate(0deg)}
100% {transform: rotate(360deg)}
`;
const Loading = styled.div`
  border-radius: 50%;
  // border: 3px solid orange;
  color: black;
  width: 8em;
  height: 8em;
  text-align: center;
  margin: auto;
  transform: translateY(7em);
  padding: 3em 0em;
  z-index: 20;
  position: relative;
  
  ::after {
    content: "";
    z-index: 20;
    border-top: 3px solid black;
    width: 8em;
    height: 8em;
    position: absolute;
    border-radius: 50%;
    left: -1em;
    top: -1em;
    animation: ${animate} 0.5s ease infinite;
  }
`;

export default Loading;
