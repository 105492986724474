// Footer.js

import React from "react";
import styles from "./Footer.module.css"; // Import your module CSS file
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faMapMarkerAlt,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

function Footer() {
  return (
    <footer className={styles["site-footer"]}>
      <div className={`container`}>
        <div className={`row`}>
          <div className="col-sm-12 col-md-3">
            <h6>A PROPOS</h6>
            {/* <p className="text-justify">
              Scanfcode.com <i>CODE WANTS TO BE SIMPLE </i> is an initiative to
              help the upcoming programmers with the code. Scanfcode focuses on
              providing the most efficient code or snippets as the code wants to
              be simple. We will help programmers build up concepts in different
              programming languages that include C, C++, Java, HTML, CSS,
              Bootstrap, JavaScript, PHP, Android, SQL, and Algorithm.
            </p> */}
            <ul className="footer-links">
              <li>
                <a href="https://www.ofppt.ma/index.php/fr/etablissement-filiere/institut-specialise-de-technologie-appliquee-hay-hassani-1-casablanca-gcecfq">
                  Qui Sommes-Nous?
                </a>
              </li>
              <li>
                <a href="contactUs">Contactez-nous</a>
              </li>
              <li>
                <a href="https://www.ofppt.ma/fr/nos-formations">
                  Nos Formations
                </a>
              </li>
            </ul>
          </div>

          <div className="col-xs-6 col-md-4">
            <h6>Contact</h6>
            <span>
              <FontAwesomeIcon className="me-3" icon={faMapMarkerAlt} />
              H898+GJ6, Bd d'Afghanistan, Casablanca
            </span>
            <br />
            <span>
              <FontAwesomeIcon className="me-3" icon={faPhone} />
              05229-03506
            </span>
            <br />
            <span>
              <FontAwesomeIcon className="me-3" icon={faEnvelope} />
              contact@istahh1.com
            </span>
          </div>

          <div className="col-xs-6 col-md-5">
            <h6>DESCRIPTION</h6>
            <p>
              le Centre de l'OFPPT Hay Hassani 1 à Casablanca est un
              établissement d'enseignement professionnel engagé à former des
              professionnels compétents et qualifiés, contribuant ainsi au
              développement socio-économique du Maroc en préparant les
              apprenants à réussir sur le marché du travail.
            </p>
          </div>
        </div>
        <hr className={styles.hr} />
      </div>
      <div className={"container"}>
        <div className={"row"}>
          <div className="col-md-8 col-sm-6 col-xs-12">
            <p className={styles["copyright-text"]}>
              &copy; Événement régional pour l'insertion professionnelle, Tous
              les droits sont inclus réservés-Right Réservé. Développé par{" "}
              <a href="#">OFPPT-Hey Hassani I</a>.
            </p>
          </div>

          <div className="col-md-4 col-sm-6 col-xs-12">
            <ul className={styles["social-icons"]}>
              <li>
                <a className={styles.facebook} href="#">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="16"
                    width="16"
                    viewBox="0 0 512 512"
                  >
                    <path d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z" />
                  </svg>
                </a>
              </li>
              <li>
                <a className={styles.twitter} href="#">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="16"
                    width="14"
                    viewBox="0 0 448 512"
                  >
                    <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM351.3 199.3v0c0 86.7-66 186.6-186.6 186.6c-37.2 0-71.7-10.8-100.7-29.4c5.3 .6 10.4 .8 15.8 .8c30.7 0 58.9-10.4 81.4-28c-28.8-.6-53-19.5-61.3-45.5c10.1 1.5 19.2 1.5 29.6-1.2c-30-6.1-52.5-32.5-52.5-64.4v-.8c8.7 4.9 18.9 7.9 29.6 8.3c-9-6-16.4-14.1-21.5-23.6s-7.8-20.2-7.7-31c0-12.2 3.2-23.4 8.9-33.1c32.3 39.8 80.8 65.8 135.2 68.6c-9.3-44.5 24-80.6 64-80.6c18.9 0 35.9 7.9 47.9 20.7c14.8-2.8 29-8.3 41.6-15.8c-4.9 15.2-15.2 28-28.8 36.1c13.2-1.4 26-5.1 37.8-10.2c-8.9 13.1-20.1 24.7-32.9 34c.2 2.8 .2 5.7 .2 8.5z" />
                  </svg>
                </a>
              </li>
              <li>
                <a className={styles.dribbble} href="#">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="16"
                    width="14"
                    viewBox="0 0 448 512"
                  >
                    <path d="M165.9 132.5c-38.3 18-66.8 53.3-75.7 95.7c6.1 .1 62.4 .3 126.4-16.7c-22.7-40.2-47.1-74.1-50.7-79zm26.1-9.1c3.8 5.1 28.6 38.9 51 80c48.6-18.3 69.1-45.9 71.6-49.4C281 124.2 235.3 112.9 192 123.4zM277.4 382c-2-12-10-53.8-29.2-103.6c-55.1 18.8-93.8 56.4-108.1 85.6c40.5 31.6 93.3 36.7 137.3 18zM227.8 232.6C159.6 253 93.4 252.2 87.4 252c0 .7 0 1.4 0 2.1s0 1.4 0 2.1c0 35.1 13.3 67.1 35.1 91.4c22.2-37.9 67.1-77.9 116.5-91.8c-3.4-7.8-7.2-15.5-11.1-23.2zm72.5 136.9c30.7-20.7 52.5-53.6 58.6-91.6c-4.6-1.5-42.3-12.7-85.1-5.8c17.9 49.1 25.1 89.1 26.5 97.4zm-34.8-119c45.5-5.7 90.7 3.4 95.2 4.4c-.3-32.3-11.8-61.9-30.9-85.1c-2.9 3.9-25.8 33.2-76.3 53.9c4.8 9.8 8.3 17.8 12 26.8zM384 32H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64zM224 416a160 160 0 1 1 0-320 160 160 0 1 1 0 320z" />
                  </svg>
                </a>
              </li>
              <li>
                <a className={styles.linkedin} href="#">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="16"
                    width="14"
                    viewBox="0 0 448 512"
                  >
                    <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
